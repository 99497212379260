import House from '@/views/house/House.vue'
import HouseDetail from '@/views/house/Detail.vue'
import HouseQrcode from '@/views/house/Qrcode.vue'
import TransferQrcode from '@/views/transfer/Qrcode.vue'
import TransferConfirm from '@/views/transfer/Confirm.vue'
import HouseTenant from '@/views/house/tenant/Tenant.vue'
import HouseTenantDetail from '@/views/house/tenant/Detail.vue'
import Declare from '@/views/declare/Declare.vue'
import DeclareSuccess from '@/views/declare/Success.vue'

const house = [{
		path: '/house/:type?',
		name: 'House',
		component: House,
	}, {
		path: '/house/detail/:id',
		name: 'HouseDetail',
		component: HouseDetail,
	}, {
		path: '/house/qrcode/:id',
		name: 'HouseQrcode',
		component: HouseQrcode,
	},
	{
		path: '/transfer/qrcode/:id',
		name: 'TransferQrcode',
		component: TransferQrcode,
	}, {
		path: '/transfer/confirm/:token',
		name: 'TransferConfirm',
		component: TransferConfirm,
	},
	{
		path: '/house/tenant/:id',
		name: 'HouseTenant',
		component: HouseTenant,
	}, {
		path: '/house/tenant/detail/:id/:tab?',
		name: 'HouseTenantDetail',
		component: HouseTenantDetail,
	},
	{
		path: '/declare',
		name: 'Declare',
		component: Declare,
	}, {
		path: '/declare/success/:id',
		name: 'DeclareSuccess',
		component: DeclareSuccess,
	}
]

export default house
