<template>
	<div class="house container">
		<van-notice-bar left-icon="volume-o" :text="tips" />
		<van-nav-bar title="居住证申领登记" left-arrow @click-left="back()" fixed />
		<Question :title="title" :button="button" />
	</div>
</template>

<script>
	import Question from "@/components/Question.vue"

	export default {
		name: "ReceiveLocal",
		data() {
			return {
				tips: "石狮派出所辖区的住户需要办理居住证的，请携带相关资料到派出所窗口办理。",
				title: "您的户籍地是否属于信州区？",
				button: [{
					text: "是",
					click: () => {
						this.$router.push({
							name: "ReceivePrompt",
							params: {
								state: 1,
							}
						});
					}
				}, {
					text: "否",
					click: () => {
						this.$router.push({
							name: "ReceiveHouse"
						});
					}
				}]
			}
		},
		components: {
			Question
		}
	}
</script>

<style lang="less" scoped>
	.house {
		padding-top: 49px;
		background-color: #F2F2F2;
	}
</style>
