import ResidencePermit from '@/views/residencePermit/ResidencePermit.vue'
import ResidencePermitDetail from '@/views/residencePermit/Detail.vue'

const residencePermit = [{
	path: '/residence_permit',
	name: 'ResidencePermit',
	component: ResidencePermit,
}, {
	path: '/residence_permit/detail/:id',
	name: 'ResidencePermitDetail',
	component: ResidencePermitDetail,
}]

export default residencePermit
