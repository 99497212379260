<template>
	<div class="qrcode success container">
		<van-nav-bar title="房屋出租" left-arrow @click-left="back()" fixed />
		<div class="success-content">
			<h3 class="success-title">请租客扫描二维码并完善租房信息</h3>
			<img class="success-img" :src="qrcode" v-if="qrcode" alt="">
			<p class="success-tips">二维码下载：请长按二维码进行保存。</p>
			<van-button class="refresh" type="info" block @click="getQrcode">刷新二维码</van-button>
		</div>
		<Preloading :loading="preloading" />
	</div>
</template>

<script>
	import Preloading from "@/components/Preloading"
	export default {
		name: "HouseQrcode",
		created() {
			this.getQrcode();
		},
		data() {
			return {
				qrcode: "",
				preloading: true,
			}
		},
		methods: {
			getQrcode() {
				let params = this.$route.params;
				this.$axios.post("/proxy/send", {
					params: {
						url: "/house/qrcode"
					},
					data: {
						id: params.id
					}
				}).then(res => {
					if (res.code == 2000) {
						this.qrcode = res.data.qrcode;
						this.preloading = false;
					} else {
						this.$toast.fail(res.msg);
					}
				});
			}
		},
		components: {
			Preloading
		}
	}
</script>

<style lang="less" scoped>

</style>
