<template>
	<div class="prompt container">
		<van-nav-bar title="居住证申领登记" left-arrow @click-left="back()" fixed />
		<div class="content">
			<h3>温馨提示</h3>
			<div v-if="state == 1">
				<p>您的户籍属于信州区无需在信州区办理居住证！</p>
			</div>
			<div v-if="state == 2">
				<p>您的基本信息不符合线上申领条件，请您携带好相关材料（身份证、户口本、房产证）前往房屋所在地派出所申领居住证。</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "ReceivePrompt",
		data() {
			return {
				state: this.$route.params.state
			}
		}
	}
</script>

<style lang="less" scoped>
	.prompt {
		padding-top: 49px;
		background-color: #F2F2F2;

		.content {
			margin: 15px;
			background-color: #fff;
			padding: 15px;

			h3 {
				color: #1772D1;
				text-align: center;
				font-size: 26px;
				font-weight: normal;
				margin: 0 0 10px;
			}

			p {
				font-size: 18px;
				line-height: 1.6;
				text-align: center;
				color: #666;
			}
		}
	}
</style>
