<template>
	<div class="prompt"></div>
</template>

<script>
	import Utils from "@/utils/util"

	export default {
		created() {
			this.checkQrcode(this.$route.params.token);
		},
		methods: {
			// 检测二维码是否失效
			checkQrcode(token) {
				this.$toast.loading("加载中");
				this.$axios.post("/proxy/send", {
					params: {
						url: "/house/checkQrcode"
					},
					data: {
						token: token
					}
				}).then((res) => {
					if (res.code == 2000) {
						this.$router.replace({
							name: 'Renting',
							params: {
								token: res.data.token,
							}
						});
						this.$toast.clear();
					} else {
						this.$toast.fail("无效的二维码");
						this.$router.back();
					}
				});
			}
		}
	}
</script>

<style lang="less" scoped>
	.prompt {
		background-color: #F2F2F2;
		padding-top: 49px;

		.prompt-center {
			text-align: center;
			padding: 70px 30px 50px;

			.prompt-icon {
				font-size: 100px;
				color: #1772D1;
				display: block;
			}

			h3 {
				font-size: 30px;
				font-weight: normal;
				color: #1772D1;
				margin: 15px 0;
			}

			p {
				color: #666;
				font-size: 15px;
				line-height: 1.8;
				margin: 0;
			}

			button {
				margin-top: 50px;
			}
		}
	}
</style>
