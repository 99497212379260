<template>
	<div class="detail container">
		<van-nav-bar title="房屋详情" left-arrow @click-left="back()" fixed />
		<div class="card">
			<div class="card-head van-hairline--bottom">
				<h3>房东信息</h3>
			</div>
			<div class="card-body">
				<div class="cell van-hairline--bottom">
					<div class="cell-title">房东姓名</div>
					<div class="cell-value">{{landlord.fullname}}</div>
				</div>
				<div class="cell van-hairline--bottom">
					<div class="cell-title">房东电话</div>
					<div class="cell-value">{{landlord.tel}}</div>
				</div>
			</div>
		</div>
		
		<div class="card">
			<div class="card-head van-hairline--bottom">
				<h3>房屋信息</h3>
			</div>
			<div class="card-body">
				<div class="cell van-hairline--bottom">
					<div class="cell-title">所属区域</div>
					<div class="cell-value">{{area_text}}</div>
				</div>
				<div class="cell van-hairline--bottom">
					<div class="cell-title">房屋地址</div>
					<div class="cell-value">{{house.address}}</div>
				</div>
				<div class="cell van-hairline--bottom">
					<div class="cell-title">详细楼层</div>
					<div class="cell-value">{{detailAddress(house)}}</div>
				</div>
				<div class="cell van-hairline--bottom">
					<div class="cell-title">房屋类型</div>
					<div class="cell-value">{{btypeText(house.btype)}}</div>
				</div>
				<div class="cell van-hairline--bottom">
					<div class="cell-title">房屋用途</div>
					<div class="cell-value">{{purposeText(house.purpose)}}</div>
				</div>
				<div class="cell van-hairline--bottom">
					<div class="cell-title">水费开户号</div>
					<div class="cell-value">{{house.water_account}}</div>
				</div>
				<div class="cell van-hairline--bottom">
					<div class="cell-title">电费开户号</div>
					<div class="cell-value">{{house.electric_account}}</div>
				</div>
				<div class="cell van-hairline--bottom">
					<div class="cell-title">燃气开户号</div>
					<div class="cell-value">{{house.gas_account}}</div>
				</div>
				<div class="cell van-hairline--bottom">
					<div class="cell-title">现场照片</div>
					<div class="cell-value">
						<div class="cell-value">
							<img class="cell-image" @click="preview(house.scene_photo_url)" :src="house.scene_photo_url">
						</div>
					</div>
				</div>
				<div class="cell van-hairline--bottom">
					<div class="cell-title">房屋照片</div>
					<div class="cell-value">
						<img class="cell-image" @click="preview(house.house_photo_url)" :src="house.house_photo_url" alt="">
					</div>
				</div>
				<div class="cell van-hairline--bottom">
					<div class="cell-title">保证书</div>
					<div class="cell-value">
						<img class="cell-image" @click="preview(house.guaranty_url)" :src="house.guaranty_url">
					</div>
				</div>
				<div class="cell van-hairline--bottom">
					<div class="cell-title">备注</div>
					<div class="cell-value">{{house.remark}}</div>
				</div>
			</div>
		</div>
		<Preloading :loading="preloading" />
	</div>
</template>

<script>
	import Vant from "@/vender/vant"
	import Utils from "@/utils/util"
	import Preloading from "@/components/Preloading"

	export default {
		name: "HouseDetail",
		data() {
			return {
				house: "",
				landlord: "",
				area_text: "",
				preloading: true
			}
		},
		created() {
			let params = this.$route.params;
			this.$axios.post('/proxy/send', {
				params: {
					url: "/house/detail"
				},
				data: {
					id: params.id
				}
			}).then(result => {
				if (result.code == 2000) {
					this.house = result.data.house
					this.landlord = result.data.landlord
					this.area_text = result.data.area_text
					this.preloading = false
				} else {
					this.$toast.fail(result.msg)
				}
			});
		},
		methods: {
			preview(images) {
				if (!(images instanceof Array)) {
					images = images.split(",");
				}
				Vant.ImagePreview({
					images: images,
					closeable: true,
				});
			},
			btypeText(btype) {
				return Utils.houseTypeToText(btype)
			},
			purposeText(purpose) {
				if (1 == purpose) {
					return "出租";
				}

				if (2 == purpose) {
					return "自住";
				}
			},
			detailAddress(data) {
				return Utils.detailAddress(data);
			}
		},
		components: {
			Preloading
		}
	}
</script>

<style lang="less" scoped>
	.detail {
		background-color: #F2F2F2;
		padding-top: 50px;

		.card-body {
			padding: 0 10px 10px;
		}
		
		span.van-tag{
			font-size: 16px;
			line-height: 1.2;
		}
	}
</style>
