import Supervise from '@/views/supervise/Supervise.vue'
import SuperviseHouse from '@/views/supervise/House.vue'
import SuperviseTenant from '@/views/supervise/Tenant.vue'
import SuperviseTenantDetail from '@/views/supervise/Detail.vue'

const supervise = [ // 民警监督
	{
		path: '/supervise',
		name: 'Supervise',
		component: Supervise,
	}, {
		path: '/supervise/house/:id?',
		name: 'SuperviseHouse',
		component: SuperviseHouse
	}, {
		path: '/supervise/tenant/:id',
		name: 'SuperviseTenant',
		component: SuperviseTenant,
	}, {
		path: '/supervise/house/detail/:id',
		name: 'SuperviseTenantDetail',
		component: SuperviseTenantDetail,
	}
]

export default supervise
