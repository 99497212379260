<template>
	<div class="guarantee">
		<div class="guarantee-content">
			<h5>请仔细阅读保证书内容</h5>
			<p>本人{{fullname}}，身份证号码{{idNumber}}，系{{address}}房屋出租责任人，现将该房屋出租，本人将严格按照《中华人民共和国反恐怖主义法》、《中华人民共和国治安管理处罚法》等相关法律法规及信州区人民政府《关于加强租赁房屋管理的通告》的要求，向公安机关申请办理居住房屋出租登记备案，并保证履行以下治安责任：</p>
			<p>1、不出租危险和违章建筑的房屋；</p>
			<p>2、房屋出租前向当地公安机关提前登记备案，或通过“安之居”流动人口管理系统进行登记备案；</p>
			<p>3、不将房屋出租给无合法有效证件的承租人;</p>
			<p>4、与承租人签订租赁合同，对承租人的姓名、公民身份号码、常住户口所在地、服务处所、照片、联系电话及承租日期等基本情况进行登记、审核，并如实通过“安之居”出租房屋管理系统上报;</p>
			<p>5、发现承租人有违法犯罪活动或有违法犯罪嫌疑的，及时报告公安机关，并配合调查;</p>
			<p>6、对出租的房屋经常进行安全检查，及时发现和排除安全隐患，保证承租人的居住安全;</p>
			<p>7、房屋停止租赁的，及时通过“安之居”出租房屋管理系统进行停租，房屋不再用于出租时，及时通过“安之居”出租房屋管理系统进行删除。</p>
			<p>8、如本人未履行相关责任，愿意承担相应的法律责任。</p>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			fullname: "",
			idNumber: "",
			address: ""
		}
	}
</script>

<style lang="less" scoped="">
	.guarantee {

		.guarantee-content {
			height: 50vh;
			overflow: auto;
			padding: 10px;

			/deep/h5 {
				text-align: center;
				color: red;
				font-size: 16px;
				margin: 0 0 10px;
				font-weight: normal;
			}

			/deep/p {
				margin: 10px 0;
				text-indent: 2em;
				font-size: 16px;
				word-break: break-all;
			}
		}
	}
</style>
