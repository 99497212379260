<template>
	<div class="success container">
		<van-nav-bar title="提交成功" left-arrow @click-left="back()" fixed />
		<div class="success-content">
			<img class="success-icon" src="@/assets/img/icon_success.png" alt="">
			<h3 class="success-title">提交成功</h3>
			<p class="success-tips">请等待房东确认信息</p>
			<van-button class="refresh" type="info" block :to="{name: 'Index'}" replace>返回</van-button>
		</div>
	</div>
</template>

<script>
	export default {
		name: "RentingSuccess"
	}
</script>

<style lang="less" scoped>

</style>
