<template>
	<div class="container success transfer">
		<van-nav-bar title="户主变更" left-arrow @click-left="back()" fixed />
		<div class="success-content">
			<h3 class="success-title">请对方描二维码</h3>
			<img class="success-img" :src="qrcode" v-if="qrcode" alt="">
			<p class="success-tips">如房屋已出售或户主变更，请对方使用微信描二维码确认过户信息</p>
			<van-button class="refresh" type="info" block @click="getQrcode">刷新二维码</van-button>
		</div>
		<Preloading :loading="preloading" />
	</div>
</template>

<script>
	import Preloading from "@/components/Preloading"

	export default {
		name: "TransferQrcode",
		created() {
			this.getQrcode()
		},
		data() {
			return {
				qrcode: '',
				preloading: true,
			}
		},
		methods: {
			getQrcode() {
				let params = this.$route.params
				this.$axios.post('/proxy/send', {
					params: {
						url: "/transfer/qrcode"
					},
					data: {
						id: params.id
					}
				}).then(res => {
					if (res.code == 2000) {
						this.qrcode = res.data.qrcode
						this.preloading = false
					} else {
						this.$toast(res.msg)
					}
				});
			}
		},
		components: {
			Preloading
		}
	}
</script>

<style lang="less" scoped="">
	.transfer {
		padding-top: 49px;
	}
</style>
