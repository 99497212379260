import Help from '@/views/help/Help.vue'
import HelpDetail from '@/views/help/Detail.vue'

const help = [{
	path: '/help',
	name: 'Help',
	component: Help,
}, {
	path: '/help/detail/:id',
	name: 'HelpDetail',
	component: HelpDetail,
}]

export default help
