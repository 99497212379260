<template>
	<div class="qrcode success container">
		<van-nav-bar title="提交成功" left-arrow @click-left="back()" fixed />
		<div class="preloading" v-if="preloading">
			<div class="preloading-center">
				<van-loading vertical type="spinner">加载中...</van-loading>
			</div>
		</div>
		<div class="success-content">
			<img class="success-icon" src="@/assets/img/icon_success.png" alt="">
			<h3 class="success-title">提交成功</h3>
			<img class="success-img" :src="qrcode" v-if="qrcode" alt="">
			<p class="success-tips">请租客扫描二维码并完善租房信息</p>
			<van-button type="info" block :to="{name: 'Index'}" replace>返回</van-button>
		</div>
	</div>
</template>

<script>
	export default {
		name: "PersonalHouseQrcode",
		created() {
			let params = this.$route.params;

			this.$axios.post('/proxy/send', {
				params: {
					url: "/house/qrcode"
				},
				data: {
					id: params.id
				}
			}).then(res => {
				if (res.code == 2000) {
					this.qrcode = res.data.qrcode;
					this.preloading = false;
				} else {
					this.$toast.fail(res.msg);
				}
			});
		},
		data() {
			return {
				qrcode: '',
				preloading: true,
			}
		}
	}
</script>

<style lang="less" scoped>

</style>
