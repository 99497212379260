import Vue from "vue"
import VueRouter from "vue-router"
import Storage from "@/storage/storage"
import Utils from "@/utils/util"
import Vant from "@/vender/vant"

import Entourage from "@/routes/entourage"
import Supervise from "@/routes/supervise"
import Tenant from "@/routes/tenant"
import House from "@/routes/house"
import Help from "@/routes/help"
import Receive from "@/routes/receive"
import Renting from "@/routes/renting"
import Personal from "@/routes/personal"
import ResidencePermit from "@/routes/residencePermit"

Vue.use(VueRouter);

import Index from "@/views/Index.vue"
import Login from "@/views/Login.vue"
import Miss from "@/views/Miss.vue"
import Enrollment from "@/views/enrollment/Enrollment.vue"

const router = new VueRouter({
	routes: [
		...Entourage,
		...Supervise,
		...ResidencePermit,
		...Tenant,
		...Receive,
		...House,
		...Help,
		...Renting,
		...Personal,
		{
			path: "/",
			name: "Index",
			component: Index,
		}, {
			path: "/enrollment",
			name: "Enrollment",
			component: Enrollment,
		}, {
			path: "/login",
			name: "Login",
			component: Login,
		}, {
			path: "*",
			name: "Miss",
			component: Miss,
		}
	],
	mode: "history",
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	}
});


// 用户信息未完善不允许访问的页面
const notPerfection = ["Declare", "Prompt", "Renting", "ReceiveHouse", "Receive", "TransferConfirm", "ReceiveLocal", "House"]

router.beforeEach((to, from, next) => {

	// 清除上个页面的全部请求
	Utils.clearAxios()
	let user = Storage.local.get("USER")

	// 检测用户信息是否完善
	if (notPerfection.indexOf(to.name) !== -1 && Utils.isEmpty(user.id_number)) {
		Vant.Dialog.confirm({
			title: "请先前往个人中心完善信息",
			confirmButtonText: "现在去",
			confirmButtonColor: "#1772D1",
			cancelButtonText: "稍后",
		}).then(() => {
			router.push({
				name: "Info",
				query: {
					redirect: to.fullPath
				}
			});
		});
	} else {
		next()
	}
})

export default router
