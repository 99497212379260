/**
 *  全局配置文件
 */

// 初始化访问地址
const ORIGIN_URL = window.location.href

// 调试模式
const IS_DEBUG = process.env.NODE_ENV === "development"

// 正式接口地址
const API_URL = "http://wx.xzga.top/api"

// 二维码地址
const QRCODE = IS_DEBUG ? "http://192.168.124.24:6004/img/qrcode.jpg" : "http://wx.xzga.top/img/qrcode.jpg"

export default {
	API_URL,
	ORIGIN_URL,
	IS_DEBUG,
	QRCODE
}