<template>
	<div class="container supervise">
		<van-nav-bar title="民警监督" left-arrow @click-left="back()" @click-right="scanCode" fixed>
			<template #right>
				<van-icon class="scancode" name="qr" />
			</template>
		</van-nav-bar>

		<div class="card">
			<div class="card-head van-hairline--bottom">
				<h3><span>统计信息</span> <span>{{dept.ps_id}}辖区</span></h3>
			</div>
			<div class="card-body">
				<div class="info van-hairline--bottom">
					<div class="info-item">
						<span class="info-item-title">街道名称</span>
						<span class="info-item-content">{{dept.jd_id}}</span>
					</div>
					<div class="info-item">
						<span class="info-item-title">包干社区</span>
						<span class="info-item-content">{{dept.jdb_id}}</span>
					</div>
					<div class="info-item">
						<span class="info-item-title">出租房屋数量</span>
						<span class="info-item-content">{{houseTotal}}</span>
					</div>
					<div class="info-item">
						<span class="info-item-title">租客数量</span>
						<span class="info-item-content">{{tenantTotal}}</span>
					</div>
					<div class="info-item">
						<span class="info-item-title">随行人员数量</span>
						<span class="info-item-content">{{entourageTotal}}</span>
					</div>
				</div>
				<div class="main" ref="main"></div>
				<p class="last-time">上次走访时间：{{lastVisitTime || '无记录'}}</p>
				<van-button class="continue" block type="info" :to="{name: 'SuperviseHouse'}">开始走访</van-button>
			</div>
		</div>
		<Preloading :loading="preloading" />
	</div>
</template>

<script>
	import Utils from "@/utils/util"
	import * as echarts from 'echarts';
	import Preloading from "@/components/Preloading"

	export default {
		data() {
			return {
				dept: "",
				user: "",
				total: "",
				houseTotal: 0,
				tenantTotal: 0,
				entourageTotal: 0,
				lastVisitTime: "",
				preloading: true,
			}
		},
		created() {

			Utils.wechat();

			this.$axios.post('/proxy/send', {
				params: {
					url: "/supervise/console"
				}
			}).then(result => {
				if (result.code == 2000) {
					this.preloading = false
					this.dept = result.data.dept
					this.user = result.data.user
					this.total = result.data.total
					this.houseTotal = result.data.house_total
					this.tenantTotal = result.data.tenant_total
					this.entourageTotal = result.data.entourage_total
					this.lastVisitTime = result.data.last_visit_time

					this.render(result.data.visited, result.data.not_visited);
				} else {
					this.$toast(result.msg);
				}
			});
		},
		methods: {
			scanCode() {
				wx.scanQRCode({
					needResult: 1,
					scanType: ["qrCode"],
					success: (qrcodeResult) => {
						let result = qrcodeResult.resultStr.match(/\/prompt\/(?<token>.*?)$/);
						if (result && result.groups.token) {
							this.getHouseId(result.groups.token);
						} else {
							this.$toast.fail("无效的二维码");
						}
					},
					error: () => {
						this.$toast.fail("二维码识别失败");
					}
				});
			},
			getHouseId(token) {
				this.$toast.loading("加载中");
				this.$axios.post("/proxy/send", {
					params: {
						url: "/supervise/qrcodeToHouseId"
					},
					data: {
						token: token
					}
				}).then((result) => {
					this.$toast.clear();
					if (result.code == 2000) {
						this.$router.push({
							name: "SuperviseHouse",
							params: {
								id: result.data.house_id
							}
						});
					} else {
						this.$toast.fail(result.msg);
					}
				});
			},
			render(visited, notVisited) {
				let option = {
					tooltip: {
						trigger: 'item'
					},
					legend: {
						left: 'center'
					},
					color: ['#62D9AD', '#5AAEF3'],
					series: [{
						name: '走访情况',
						type: 'pie',
						radius: '60%',
						label: {
							fontSize: 16
						},
						itemStyle: {
							normal: {
								label: {
									show: true,
									position: 'inner',
									formatter: '{c}',
									textStyle: {
										fontSize: 16,
										color: "#fff"
									},
								},
								labelLine: {
									show: false
								}
							},
						},
						data: [{
								value: visited,
								name: "已走访",
							},
							{
								value: notVisited,
								name: "未走访",
							}
						]
					}]
				};
				let myChart = echarts.init(this.$refs.main);
				myChart.setOption(option);
			}
		},
		components: {
			Preloading
		}
	}
</script>

<style lang="less" scoped="">
	.supervise {
		background-color: #F2F2F2;
		box-sizing: border-box;
		padding-top: 49px;
		min-height: 100vh;

		.card {
			.card-head h3 {
				display: flex;
				justify-content: space-between;

				&:last-child {
					padding-right: 10px;
				}
			}

			.card-body {
				padding: 0 10px 10px;

				.main {
					width: 100%;
					height: 280px;
					padding-top: 15px;
				}

				.last-time {
					text-align: center;
					color: #999;
					font-size: 18px;
					margin: 0 0 30px;
				}

				.info {
					padding: 10px 0;

					.info-item {
						display: flex;
						padding: 5px 0;

						.info-item-title {
							width: 130px;
							min-width: 130px;
							font-size: 18px;
							color: #333;
						}

						.info-item-content {
							font-size: 18px;
							color: #999;
						}
					}
				}
			}
		}

		.scancode {
			font-size: 18px;
		}

		.van-button {
			font-size: 18px;
		}
	}
</style>
