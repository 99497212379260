<template>
	<div class="house container">
		<van-nav-bar :title="page.type == 1 ? '我的房屋' : '代录入房屋'" left-arrow @click-left="back()" fixed />
		<van-list v-model="loading" :finished="finished" finished-text="没有更多啦" @load="loadData">
			<div class="house-wrap cell-list-wrap">
				<div class="cell-list" v-for="(item, index) in house">
					<div class="cell-list-item">
						<van-icon name="location-o" />
						<div class="cell-list-item-title">所属区域：</div>
						<div class="cell-list-item-text">{{item.area_text}}</div>
					</div>
					<div class="cell-list-item">
						<van-icon name="wap-home-o" />
						<div class="cell-list-item-title">房屋地址：</div>
						<div class="cell-list-item-text">{{item.address}}</div>
					</div>
					<div class="cell-list-item">
						<van-icon name="hotel-o" />
						<div class="cell-list-item-title">详细楼层：</div>
						<div class="cell-list-item-text">{{detailAddress(item)}}</div>
					</div>
					<div class="cell-list-item">
						<van-icon name="friends-o" />
						<div class="cell-list-item-title">租客人数：</div>
						<div class="cell-list-item-text">{{item.tenant_total}}</div>
					</div>
					<div class="cell-list-btns van-hairline--top">
						<van-button type="primary" size="small" color="#46B7FB" :to="{name: 'HouseDetail', params: {id: item.id}}">房屋详情</van-button>
						<van-button type="primary" size="small" color="#07C160" :to="{name: 'HouseTenant', params: {id: item.id}}">查看租客
							<van-tag type="danger" v-if="item.tenant_pending_total > 0">{{item.tenant_pending_total}}</van-tag>
						</van-button>
						<van-button type="info" size="small" color="#07C189" :to="{name: 'HouseQrcode', params: {id: item.id}}">房屋出租</van-button>
						<van-button type="info" size="small" color="#FF976A" :to="{name: 'TransferQrcode', params: {id: item.id}}">户主变更</van-button>
						<van-button type="info" size="small" color="#FF0000" @click="del(item, index)">房屋删除</van-button>
					</div>
				</div>
			</div>
		</van-list>
		<div class="empty" v-if="house.length <= 0">
			<van-empty description="未查询到相关数据" />
		</div>
		<div class="flex-bottom">
			<van-button type="primary" class="add-house" block color="#46B7FB" :to="{name: 'Declare'}">新增出租房屋</van-button>
		</div>
		<Preloading :loading="preloading" />
	</div>
</template>

<script>
	const keepAlive = ["HouseQrcode", "HouseDetail", "HouseTenant", "TransferQrcode"];

	import Utils from "@/utils/util"
	import Event from "@/store/event"
	import Preloading from "@/components/Preloading"

	export default {
		name: "House",
		data() {
			return {
				house: [],
				preloading: true,
				loading: false,
				finished: false,
				page: {
					type: 1,
					start: 0,
					limit: 10,
				}
			}
		},
		created() {
			this.page.type = this.$route.params.type || 1
		},
		mounted() {
			// 监听减少租客信息
			Event.$on("house_count", (data) => {
				let index = this.house.findIndex(item => item.id == data.id)
				this.house[index]["tenant_pending_total"] = this.house[index]["tenant_pending_total"] - 1;
			})
		},
		destroyed() {
			Event.$off('house_count')
		},
		methods: {
			loadData() {
				this.$axios.post('/proxy/send', {
					params: {
						url: "/house/lists"
					},
					data: this.page
				}).then(result => {
					if (result.code == 2000) {
						this.loading = false;
						this.house = this.house.concat(result.data.list);
						this.page.start = this.house.length;
						this.preloading = false;
						if (this.house.length >= result.data.total) {
							this.finished = true;
						}
						if (this.house.length <= 0) {
							this.$router.replace({
								name: 'Declare'
							})
						}
					} else {
						this.$toast.fail(result.msg)
					}
				});
			},
			detailAddress(data) {
				return Utils.detailAddress(data);
			},
			del(data, index) {
				this.$dialog.confirm({
					title: "提示",
					message: "确认删除此房屋吗？",
					confirmButtonColor: "#1772D1",
				}).then(() => {
					this.$axios.post('/proxy/send', {
						params: {
							url: "/house/del"
						},
						data: {
							id: data.id
						}
					}).then(res => {
						if (res.code == 2000) {
							this.house.splice(index, 1);
							this.$toast.success("删除成功");
						} else {
							this.$toast.fail(res.msg);
						}
					});
				});
			}
		},
		beforeRouteEnter(to, from, next) {
			next((vm) => {
				vm.$keepAlive.enter(to, from, vm, keepAlive);
			});
		},
		beforeRouteLeave(to, from, next) {
			this.$keepAlive.leave(to, from, this, keepAlive);
			next();
		},
		components: {
			Preloading
		}
	}
</script>

<style lang="less" scoped>
	.house {
		background-color: #F2F2F2;
		padding-top: 50px;
			
		.van-list {
			padding-bottom: 70px;
		}

		.cell-list-wrap .cell-list {
			padding-top: 10px;
		}

		.flex-bottom {
			position: fixed;
			bottom: 0;
			width: 100%;
			padding: 16px;
			box-sizing: border-box;
			
			.add-house{
				font-size: 20px;
			}
		}

		.h-76 {
			height: 76px;
		}
	}
</style>
