<template>
	<div class="personal-information container">
		<van-nav-bar title="我的资料" left-arrow @click-left="back()" fixed />
		<div class="card">
			<div class="card-head van-hairline--bottom">
				<h3>个人信息</h3>
			</div>
			<div class="card-body">
				<van-form class="form" @submit="onSubmit">
					<div class="upload-idcard">
						<div class="upload-idcard-item">
							<van-uploader v-model="show.id_fp_url" capture :after-read="afterRead" :upload-icon="require('@/assets/img/img13.png')" :max-count="1" :preview-options="{closeable: true}" @delete="deleteIdCard" />
							<p>身份证正面</p>
						</div>
						<div class="upload-idcard-item upload-example">
							<img src="@/assets/img/img14.png" alt="">
							<p>示例图片</p>
						</div>
					</div>
					<div class="upload-tips">
						请将您的证件对齐取景框四周，拍照后会自动识别证件信息
					</div>
					<van-field required :disabled="show.allowInput" v-model="data.fullname" name="fullname" label="姓名" placeholder="请输入您的姓名" />
					<van-field required :disabled="show.allowInput" v-model="data.id_number" name="id_number" label="身份证号" placeholder="请输入您的身份证号" />
					<van-field required :disabled="show.allowInput" v-model="data.nation" label="民族" placeholder="请输入您的民族" />
					<van-field required label="性别" :disabled="show.allowInput">
						<template #input>
							<van-radio-group v-model="data.sex" direction="horizontal" :disabled="show.allowInput">
								<van-radio name="1">男</van-radio>
								<van-radio name="2">女</van-radio>
							</van-radio-group>
						</template>
					</van-field>
					<van-field required :disabled="show.allowInput" readonly clickable @click="show.showSelectBirthDate = true" v-model="data.birth_date" label="出生日期" placeholder="请选择您的出生日期" />
					<van-popup v-model="show.showSelectBirthDate" position="bottom">
						<van-datetime-picker v-model="show.birthDate" title="出生日期" type="date" show-toolbar @confirm="selectBirthDate" @cancel="show.showSelectBirthDate = false" :min-date="show.birthDateMinDate" :max-date="show.birthDateMaxDate" />
					</van-popup>
					<van-field required :disabled="show.allowInput" v-model="data.address" name="address" label="户籍地址" placeholder="请输入您的户籍地址" />
					<van-field required :disabled="show.allowInput" v-model="data.tel" name="tel" label="联系电话" placeholder="请输入您的联系电话" />
					<van-field :disabled="show.allowInput" v-model="data.tel_replace" name="tel_replace" label="备用电话" placeholder="请输入您的备用电话" />
					<div class="submit">
						<van-button block type="info" native-type="submit">保存</van-button>
					</div>
				</van-form>
			</div>
		</div>
	</div>
</template>

<script>
	import Storage from '@/storage/storage'
	import Compressor from 'compressorjs'
	import Validator from "we-validator"
	import Utils from "@/utils/util"

	let currentDate = new Date();
	let minDate = new Date(currentDate.getFullYear() - 70, currentDate.getMonth(), currentDate.getDate());
	let maxDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

	export default {
		data() {
			return {
				show: {
					allowInput: true,
					birthDate: currentDate,
					birthDateMinDate: minDate,
					birthDateMaxDate: maxDate,
					showSelectBirthDate: false,
					id_fp_url: [],
				},
				data: {
					fullname: "",
					id_number: "",
					address: "",
					tel: "",
					tel_replace: "",
					id_fp: "",
					sex: "",
					birth_date: "",
					nation: "",
				}
			};
		},
		created() {
			let user = Storage.local.get("USER");

			this.data.tel = user.tel;
			this.data.nation = user.nation;
			this.data.address = user.address;
			this.data.fullname = user.fullname;
			this.data.sex = user.sex.toString();
			this.data.id_number = user.id_number;
			this.data.tel_replace = user.tel_replace;
			this.show.birthDate = new Date(user.birth_date);
			this.data.birth_date = user.birth_date == "0000-00-00" ? "" : user.birth_date;

			if (!Utils.isEmpty(user.id_fp)) {
				let id_fp = this.setUploadImage(user.id_fp_url, user.id_fp)
				this.show.id_fp_url = [id_fp]
				this.show.allowInput = false
			}

			this.validatorInit()
		},
		methods: {
			onSubmit(values) {
				let data = this.data;
				data.id_fp = this.getUploadImage(this.show.id_fp_url);

				let result = this.validator.checkData(data, result => {
					this.$toast(result.msg);
				})
				if (false == result) {
					return false
				}

				this.$toast.loading({
					message: "正在提交..."
				});

				this.$axios.post('/proxy/send', {
					params: {
						url: "/user/edit"
					},
					data: data
				}).then(res => {
					if (res.code == 2000) {
						this.$toast.clear();
						// 更新缓存数据
						let user = res.data;
						let original = Storage.local.get("USER");
						user.manager = original.manager;
						Storage.local.set("USER", user);
						this.$router.replace({
							name: "InfoSuccess",
							query: this.$route.query
						});
					} else {
						this.$toast.fail(res.msg);
					}
				});
			},
			afterRead(file) {
				file.status = 'uploading';
				file.message = '识别中...';
				new Compressor(file.file, {
					maxWidth: 800,
					quality: 0.8,
					success: (result) => {
						let data = new FormData();
						data.append('idCard', result, result.name);
						this.$axios.post("/upload/idCard", data).then((res) => {
							if (res.code == 2000) {
								file.status = 'done';
								file.message = '识别成功';
								file.content = res.data.url;
								file.relpath = res.data.relpath;

								let data = res.data;
								this.data.fullname = data.name;
								this.data.id_number = data.id_number;
								this.data.nation = data.nation;
								this.data.sex = data.sex.toString();
								this.data.address = data.address;
								this.data.birth_date = data.birth_date;
								this.show.birthDate = new Date(data.birth_date);
								this.show.allowInput = false;
							} else {
								file.status = 'failed';
								file.message = res.msg;
							}
						});
					},
					error() {
						this.$toast.fail("证件图片压缩失败！");
					}
				});
			},
			selectBirthDate(val) {
				this.data.birth_date = Utils.formatDate(val);
				this.show.showSelectBirthDate = false;
			},
			deleteIdCard() {
				this.show.allowInput = true;
			},
			setUploadImage(url, relpath) {
				return {
					url: url,
					relpath: relpath
				}
			},
			getUploadImage(data) {
				let result = [];
				for (let i = 0; i < data.length; i++) {
					if (!Utils.isEmpty(data[i].relpath)) {
						result.push(data[i].relpath)
					}
				}
				return result.length == 1 ? result[0] : result
			},
			validatorInit() {
				// 初始化验证器
				this.validator = new Validator({
					rules: {
						fullname: {
							required: true
						},
						id_number: {
							idcard: true,
							required: true
						},
						nation: {
							required: true
						},
						birth_date: {
							required: true
						},
						address: {
							required: true
						},
						tel: {
							mobile: true,
							required: true
						},
						tel_replace: {
							mobile: true
						},
						id_fp: {
							required: true
						}
					},
					messages: {
						fullname: {
							required: "请输入姓名"
						},
						id_number: {
							idcard: "身份证号格式错误",
							required: "请输入身份证号"
						},
						nation: {
							required: "请输入民族"
						},
						birth_date: {
							required: "请选择出生日期"
						},
						address: {
							required: "请输入户籍所地址"
						},
						tel: {
							mobile: "请输入正确的11位电话号码",
							required: "请输入电话号码"
						},
						tel_replace: {
							mobile: "请输入正确的11位备用电话"
						},
						id_fp: {
							required: "请上传身份证正面"
						}
					}
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	@import "@/assets/less/form.less";

	.personal-information {
		background-color: #F2F2F2;
		padding-top: 50px;

		.upload-example {
			img {
				width: 135px;
			}
		}

		.upload-tips {
			margin: 0 10px;
			padding: 10px;
			background-color: #fffbe8;
			font-size: 16px;
			color: #ed6a0c;
		}

		.upload-idcard {
			padding-bottom: 5px;
		}
	}
</style>
