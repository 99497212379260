import Vue from 'vue'
import axios from 'axios'
import App from '@/App.vue'
import router from '@/router'
import Vant from '@/vender/vant'
import store from '@/store/store'
import keepAlive from '@/store/keepAlive';
import Interceptors from '@/middleware/interceptors'


// 挂载原型
Vue.config.productionTip = true

Vue.prototype.$axios = axios
Vue.prototype.$keepAlive = keepAlive
Vue.prototype.back = function(route) {
	this.$router.go(-1);
}

new Vue({
	store,
	router,
	render: h => h(App),
}).$mount('#app')
