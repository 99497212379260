<template>
	<div class="preloading" v-if="loading">
		<div class="preloading-center">
			<van-loading vertical type="spinner">加载中...</van-loading>
		</div>
	</div>
</template>

<script>
	export default {
		created(){
			this.hidden()
		},
		props: {
			loading: {
				default: false
			}
		},
		watch: {
			loading(newVal) {
				newVal == false ? this.visible() : this.hidden()
			}
		},
		destroyed() {
			this.visible()
		},
		methods: {
			hidden() {
				document.body.style.overflow = "hidden"
			},
			visible() {
				document.body.style.overflow = null
			}
		}
	}
</script>

<style lang="less" scoped="">
	.preloading {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: #fff;
		z-index: 10;

		.preloading-center {
			position: fixed;
			top: calc(50% - 50px);
			left: 50%;
			transform: translate(-50%, -50%);
			-webkit-transform: translate(-50%, -50%);
		}
	}
</style>
