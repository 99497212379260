<template>
	<div class="house container" ref="scroll">
		<van-nav-bar title="租客列表" left-arrow @click-left="back()" fixed />
		<van-list v-model="loading" :finished="finished" finished-text="没有更多啦" @load="loadData">
			<div class="house-wrap cell-list-wrap">
				<div :class="['cell-list', item.status == 4 ||  item.is_expired == 1 ? 'cell-disabled' : '']" v-for="item in house">
					<template v-if="item.is_expired == 1 && item.status != 4">
						<span class="cell-list-state cell-list-state-error">已到期</span>
					</template>
					<template v-else>
						<span v-if="item.status == 1" class="cell-list-state cell-list-state-error">待确认</span>
						<span v-if="item.status == 3" class="cell-list-state cell-list-state-error">待解约</span>
						<span v-if="item.status == 4" class="cell-list-state cell-list-state-error">已解约</span>
					</template>
					<div class="cell-list-item">
						<van-icon name="user-o" />
						<div class="cell-list-item-title">租客姓名：</div>
						<div class="cell-list-item-text">{{item.fullname}}</div>
					</div>
					<div class="cell-list-item">
						<van-icon name="phone-o" />
						<div class="cell-list-item-title">联系电话：</div>
						<div class="cell-list-item-text">{{item.tel}}</div>
					</div>
					<div class="cell-list-item">
						<van-icon name="idcard" />
						<div class="cell-list-item-title">身份证号：</div>
						<div class="cell-list-item-text">{{item.id_number}}</div>
					</div>
					<div class="cell-list-item">
						<van-icon name="location-o" />
						<div class="cell-list-item-title">户籍地址：</div>
						<div class="cell-list-item-text">
							<span class="cell-wrap-text">{{item.address}}</span>
						</div>
					</div>
					<div class="cell-list-item">
						<van-icon name="clock-o" />
						<div class="cell-list-item-title">承租时间：</div>
						<div class="cell-list-item-text">{{formatDate(item.start_date)}} - {{formatDate(item.end_date)}}</div>
					</div>
					<div class="cell-list-btns van-hairline--top">
						<van-button type="primary" size="small" color="#46B7FB" :to="{name: 'HouseTenantDetail', params: {id: item.id}}">租客详情
							<van-tag type="danger" v-if="item.pending_total > 0">{{item.pending_total}}</van-tag>
						</van-button>
					</div>
				</div>
			</div>
		</van-list>
		<div class="empty" v-if="house.length <= 0">
			<van-empty description="未查询到相关数据" />
		</div>
		<Preloading :loading="preloading" />
	</div>
</template>

<script>
	import Utils from "@/utils/util"
	import Event from "@/store/event"
	import Preloading from "@/components/Preloading"

	const keepAlive = ["HouseTenantDetail"];

	export default {
		name: "HouseTenant",
		data() {
			return {
				house: [],
				id: this.$route.params.id,
				preloading: true,
				loading: false,
				finished: false,
				page: {
					start: 0,
					limit: 10,
				},
			}
		},
		mounted() {
			// 监听减少统计
			Event.$on("tenant_count", data => {
				let index = this.house.findIndex(item => item.id == data.id);
				this.house[index]['pending_total']--
			})

			// 监听减少租客信息
			Event.$on("tenant_update", data => {
				let index = this.house.findIndex(item => item.id == data.id);
				for (let vo in data) {
					this.house[index][vo] = data[vo];
				}
			})

			// 监听减少租客信息
			Event.$on("tenant_delete", data => {
				let index = this.house.findIndex(item => item.id == data.id);
				this.house.splice(index, 1);
			})
		},
		methods: {
			loadData() {
				let params = {
					id: this.id,
					start: this.page.start,
					limit: this.page.limit,
				}
				this.$axios.post('/proxy/send', {
					params: {
						url: "/house/tenant"
					},
					data: params
				}).then(res => {
					if (res.code == 2000) {
						this.house = this.house.concat(res.data.list);
						this.page.start = this.house.length;
						if (this.house.length >= res.data.total) {
							this.finished = true;
						}
						this.loading = false;
						this.preloading = false;
					} else {
						this.$toast(res.msg);
					}
				});
			},
			formatDate(date) {
				return Utils.formatDate(new Date(date), "/")
			}
		},
		beforeRouteEnter(to, from, next) {
			next((vm) => {
				vm.$keepAlive.enter(to, from, vm, keepAlive);
			});
		},
		beforeRouteLeave(to, from, next) {
			this.$keepAlive.leave(to, from, this, keepAlive);
			next();
		},
		destroyed() {
			Event.$off('tenant_count')
			Event.$off('tenant_update')
			Event.$off('tenant_delete')
		},
		components: {
			Preloading
		}
	}
</script>

<style lang="less" scoped>
	.house {
		background-color: #F2F2F2;
		padding-top: 50px;
	}
</style>
