<template>
	<div class="success container">
		<van-nav-bar title="个人信息" left-arrow @click-left="back()" fixed />
		<div class="success-content">
			<img class="success-icon" src="@/assets/img/icon_success.png" alt="">
			<h3 class="success-title">修改成功</h3>
			<van-button class="refresh" type="info" block @click="toback">返回</van-button>
		</div>
	</div>
</template>

<script>
	export default {
		name: "PersonalInformationSuccess",
		methods: {
			toback() {
				let query = this.$route.query;
				if (query.redirect) {
					this.$router.replace(query.redirect);
				} else {
					this.$router.replace({
						name: "Personal"
					});
					this.$router.go(-1);
				}
			}
		}
	}
</script>

<style lang="less" scoped>

</style>
