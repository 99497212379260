<template>
	<div class="oauth container">
		<p class="oauth-ing">授权中...</p>
	</div>
</template>

<script>
	import Storage from '@/storage/storage'

	export default {
		created() {
			let query = this.$route.query;

			if (Storage.local.get("UNIQUE") == query.unique) {
				Storage.local.set("TOKEN", query.token);
			}

			// 携带重定向信息回到首页
			let params = {}
			if (query.redirect) {
				params.redirect = query.redirect
			}

			this.$router.replace({
				name: "Index",
				query: params
			})
		}
	}
</script>

<style lang="less" scoped>
	@import "@/assets/less/common.less";

	.oauth-ing {
		text-align: center;
		font-size: 18px;
		font-weight: bold;
		margin-top: 90px;
		color: #07c160;
	}
</style>
