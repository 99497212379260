/**
 * 缓存
 */
import webStorage from 'web-storage-cache';

const session = new webStorage({
	storage: 'sessionStorage',
	exp: Infinity
});

const local = new webStorage({
	storage: 'localStorage',
	exp: Infinity
});

export default {
	session,
	local
}
