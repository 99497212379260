<template>
	<div class="house container">
		<van-nav-bar title="我的居住证" left-arrow @click-left="back()" fixed />
		<div class="preloading" v-if="preloading">
			<div class="preloading-center">
				<van-loading vertical type="spinner">加载中...</van-loading>
			</div>
		</div>
		<van-list v-model="loading" :finished="finished" finished-text="没有更多啦" @load="loadData">
			<div class="house-wrap cell-list-wrap">
				<div class="cell-list" v-for="item in house">
					<span v-if="item.status == 1" class="cell-list-state cell-list-state-error">待审核</span>
					<span v-if="item.status == 2" class="cell-list-state cell-list-state-success">审核通过</span>
					<span v-if="item.status == 3" class="cell-list-state cell-list-state-error">未通过</span>
					<div class="cell-list-item">
						<van-icon name="user-o" />
						<div class="cell-list-item-title">申领人：</div>
						<div class="cell-list-item-text">{{item.fullname}}</div>
					</div>
					<div class="cell-list-item">
						<van-icon name="phone-o" />
						<div class="cell-list-item-title">电话：</div>
						<div class="cell-list-item-text">{{item.tel}}</div>
					</div>
					<div class="cell-list-item">
						<van-icon name="idcard" />
						<div class="cell-list-item-title">身份证号：</div>
						<div class="cell-list-item-text">{{item.id_number}}</div>
					</div>
					<div class="cell-list-item">
						<van-icon name="location-o" />
						<div class="cell-list-item-title">户籍地址：</div>
						<div class="cell-list-item-text">
							<span class="cell-wrap-text">{{item.id_address}}</span>
						</div>
					</div>
					<div class="cell-list-item">
						<van-icon name="clock-o" />
						<div class="cell-list-item-title">申领时间：</div>
						<div class="cell-list-item-text">{{item.create_time}}</div>
					</div>
					<div class="cell-list-btns van-hairline--top">
						<van-button type="primary" size="small" color="#46B7FB" :to="{name: 'ResidencePermitDetail', params: {id: item.id}}">
							查看详情
						</van-button>
					</div>
				</div>
			</div>
		</van-list>
		<div class="empty" v-if="house.length <= 0">
			<van-empty description="未查询到相关数据" />
		</div>
	</div>
</template>

<script>
	const keepAlive = ["ResidencePermitDetail"];

	export default {
		name: "ResidencePermit",
		data() {
			return {
				house: [],
				loading: false,
				finished: false,
				preloading: true,
				page: {
					start: 0,
					limit: 10,
				}
			}
		},
		beforeRouteEnter(to, from, next) {
			next((vm) => {
				vm.$keepAlive.enter(to, from, vm, keepAlive);
			});
		},
		beforeRouteLeave(to, from, next) {
			this.$keepAlive.leave(to, from, this, keepAlive);
			next();
		},
		methods: {
			loadData() {
				this.$axios.post('/proxy/send', {
					params: {
						url: "/residence_permit/lst"
					},
					data: this.page
				}).then(res => {
					if (res.code == 2000) {
						this.loading = false;
						this.house = this.house.concat(res.data.list);
						this.page.start = this.house.length;
						if (this.house.length >= res.data.total) {
							this.finished = true;
						}
						this.preloading = false;
					} else {
						this.$toast.fail(res.msg)
					}
				});
			}
		}
	}
</script>

<style lang="less" scoped>
	.house {
		background-color: #F2F2F2;
		padding-top: 50px;
	}
</style>
