<template>
	<div id="app">
		<keep-alive :include="keepAlive">
			<router-view class="child-view" />
		</keep-alive>
		<van-popup class="qrcode-container" v-model="showQrcode" closeable close-icon="close" :style="{width: '70%', borderRadius: '5px'}">
			<div class="qrcode">
				<h3>温馨提示</h3>
				<p>您还未关注公众号，关注公众号即可实时收到消息</p>
				<img :src="qrcode" alt="">
				<van-button type="info" color="#1772D1" block round @click="reload">我已关注</van-button>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import Config from "@/config/config"
	import Storage from "@/storage/storage"

	export default {
		name: 'App',
		data() {
			return {
				showQrcode: false,
				qrcode: Config.QRCODE
			}
		},
		created() {
			this.$toast.loading({
				message: "登录中"
			})

			// 初始化用户信息
			this.$axios.post('/proxy/send', {
				params: {
					url: "/user/info"
				}
			}).then(result => {
				if (result.code == 2000) {
					let user = result.data
					Storage.local.set("USER", user)
					Storage.session.set("LOGGED", true)
					this.showQrcode = user.subscribe != 1 ? true : false
					this.checkRedirect()
				}
				this.$toast.clear();
			})
		},
		methods: {
			checkRedirect() {
				let query = this.$route.query
				// 检测是否需要重定向
				if (query.redirect && Storage.session.get("redirect") == null) {
					let redirect = query.redirect.replace(/\/\?redirect=/, "");
					Storage.session.set("redirect", "success");
					this.$router.push({
						path: redirect
					})
				}
			},
			reload() {
				Storage.local.clear()
				Storage.session.clear()
				window.location.reload()
			}
		},
		computed: {
			keepAlive() {
				return this.$store.state.keepAlive;
			}
		}
	}
</script>

<style lang="less">
	@import "./assets/less/common.less";

	.van-toast__text {
		word-break: break-all;
	}

	.container {
		.van-nav-bar {
			background-color: #1772D1;
			z-index: 100;
		}

		.van-nav-bar__content {
			height: 49px;
		}

		.van-nav-bar__title {
			color: #fff;
			font-size: 18px;
		}

		.van-nav-bar .van-icon {
			color: #fff;
		}
	}


	.block-required:before {
		content: "*";
		color: #ee0a24;
		margin-left: -9px;
		margin-right: 2px;
	}

	.tabbar {
		margin: 10px;
		display: flex;
		border-radius: 3px;
		border: 1px solid #1772D1;
		overflow: hidden;

		.tabbar-item {
			flex: 1;
			text-align: center;
			padding: 5px;
			background-color: #fff;
			font-size: 15px;
			color: #1772D1;

			&.active {
				background-color: #1772D1;
				color: #fff;
			}
		}
	}

	.qrcode-container {

		.van-popup__close-icon--top-right {
			top: 10px;
			right: 10px;
		}

		.qrcode {
			padding: 30px 20px;

			h3 {
				margin: 0;
				color: #1772D1;
				font-size: 20px;
				font-weight: normal;
				text-align: center;
				margin-bottom: 10px;
			}

			img {
				display: block;
				padding: 10px 0 15px;
				max-width: 80%;
				margin: 0 auto;
			}

			p {
				font-size: 14px;
				line-height: 1.6;
				color: #666;
				margin: 0;
				text-align: center;
			}
		}
	}

	.empty-tips {
		text-align: center;
		color: #999;
		font-size: 15px;
		padding: 30px 0 20px;
		margin: 0;
	}

	.van-empty .van-empty__description {
		font-size: 18px;
	}

	.van-list .van-list__error-text,
	.van-list .van-list__finished-text,
	.van-list .van-list__loading {
		font-size: 16px;
	}


	.van-notice-bar__wrap .van-notice-bar__content {
		font-size: 16px;
	}

	.van-toast .van-toast__text {
		font-size: 16px;
	}

	.van-field__value .van-field__word-limit {
		font-size: 16px;
	}

	.tabbar .tabbar-item {
		padding: 8px;
		font-size: 18px;
	}

	.van-tabs .van-tab__text {
		font-size: 18px;
	}

	.van-dropdown-menu .van-ellipsis {
		font-size: 16px;
	}

	.van-dropdown-item--down .van-cell__title {
		font-size: 16px;
	}

	.van-picker {

		.van-picker__cancel,
		.van-picker__confirm,
		.van-ellipsis {
			font-size: 18px;
		}
	}

	.van-cascader {
		.van-cascader__option {
			font-size: 16px;
		}
	}

	.van-dialog {
		.van-dialog__header {
			font-size: 20px;
		}

		.van-dialog__message {
			font-size: 18px;
		}

		.van-button__text {
			font-size: 18px;
		}
	}
</style>
