<template>
	<div class="content">
		<h3>{{title}}</h3>
		<van-button block type="default" @click="item.click" v-for="item in button">{{item.text}}</van-button>
	</div>
</template>

<script>
	export default {
		props: {
			title: "",
			button: ""
		}
	}
</script>

<style lang="less" scoped="">
	.content {
		padding: 15px;

		h3 {
			color: #1772D1;
			font-weight: normal;
			font-size: 26px;
			text-align: center;
			margin: 64px 0 30px;
		}

		.van-button {
			margin-bottom: 15px;
			font-size: 18px;
			height: 50px;
		}
	}
</style>
