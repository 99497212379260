import axios from 'axios'
import router from '@/router'
import Vant from '@/vender/vant'
import Storage from '@/storage/storage'
import Config from "@/config/config"

// 登录中状态保存，防止两个请求同时返回未登录导致同时登录
let loging = false;

// 基础配置
axios.defaults.baseURL = Config.IS_DEBUG ? "" : Config.API_URL
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.timeout = 10000

// 全局CancelToken
window.__AxiosRequestCancelTokenList = []

// 请求拦截器
axios.interceptors.request.use((config) => {
	config.headers['Token'] = Storage.local.get("TOKEN")
	// 添加到全局CancelToken
	config.cancelToken = new axios.CancelToken(cancel => {
		window.__AxiosRequestCancelTokenList.push({
			cancel
		})
	})
	return config;
});

//响应拦截器
axios.interceptors.response.use(response => {

	var result = response.data;
	let data = result.data;

	// 用户未登录，重定向至登录界面
	if (result.code == 3001 && loging == false) {
		loging = true
		// 缓存唯一标识，解决非同一终端问题
		Storage.local.set("UNIQUE", data.unique)

		let url = data.redirect + "?unique=" + data.unique + "&redirect=" + router.currentRoute.fullPath
		window.location.replace(url)
	}

	// 接口返回错误
	if (result.code == 5000) {
		Vant.Toast.fail('服务器异常');
	}

	// 页面不存在
	if (result.code == 6000) {
		router.replace({
			name: "Miss"
		})
	}

	return result;
}, error => {
	// 主动取消请求不返回异常
	if (axios.isCancel(error)) {
		return new Promise(() => {})
	}
	Vant.Toast.fail('网络错误！')
	return Promise.reject(error);
});
