import store from '@/store/store';

const keepAlive = {
	enter(to, from, vm, keepAlive) {
		if (keepAlive.indexOf(from.name) === -1) {
			store.commit("setKeepAlive", to.name);
		}
	},
	leave(to, from, vm, keepAlive){
		if (keepAlive.indexOf(to.name) === -1) {
			store.commit("removeKeepAlive", from.name);
		}
		vm.state = 'activated';
	},
	activated(vm, fn){
		if (vm.state == 'activated') {
			if (fn && (fn instanceof Function)) {
				fn();
			}
		}
	}
}

export default keepAlive;