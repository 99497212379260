<template>
	<div class="detail container" ref="scroll">
		<van-nav-bar title="帮助详情" left-arrow @click-left="back()" fixed />
		<div class="card">
			<div class="card-body">
				<h1 class="title van-hairline--bottom">{{detail.title}}</h1>
				<div class="content" v-html="detail.content"></div>
			</div>
		</div>
		<Preloading :loading="preloading"/>
	</div>
</template>

<script>
	import Preloading from "@/components/Preloading"

	export default {
		name: "HelpDetail",
		data() {
			return {
				detail: "",
				preloading: true,
			}
		},
		created() {
			let params = this.$route.params;
			this.$axios.post('/proxy/send', {
				params: {
					url: "/article/detail"
				},
				data: {
					id: params.id,
				}
			}).then(res => {
				if (res.code == 2000) {
					this.detail = res.data;
					this.preloading = false;
				} else {
					this.$toast(res.msg)
				}
			});
		},
		components: {
			Preloading
		}
	}
</script>

<style lang="less" scoped>
	.detail {
		background-color: #F2F2F2;
		padding-top: 50px;

		.card-body {
			padding: 15px;

			.title {
				font-size: 22px;
				font-weight: normal;
				text-align: center;
				padding-bottom: 15px;
				margin-bottom: 15px;
			}

			.content {
				font-size: 18px;
				color: #666;
				line-height: 1.8;

				/deep/ img {
					display: block;
				}
				
				/deep/ video{
					max-width: 100%;
					object-fit: cover;
				}
			}
		}
	}
</style>
