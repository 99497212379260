<template>
	<div class="declare container" ref="scroll">
		<van-nav-bar title="过户确认" left-arrow @click-left="back()" fixed />
		<div class="card">
			<div class="card-head van-hairline--bottom">
				<h3>房屋信息</h3>
			</div>
			<div class="card-body">
				<van-form class="form">
					<div class="radio van-hairline--bottom">
						<p class="radio-title block-required">房屋用途</p>
						<div class="radio-wrap">
							<div class="radio-item radio-item-active">
								<label class="radio-label">
									<input type="radio" value="1" v-model="data.purpose"><span>出租</span>
								</label>
							</div>
							<div class="radio-item">
								<label class="radio-label">
									<input type="radio" value="2" v-model="data.purpose"><span>自住</span>
								</label>
							</div>
						</div>
					</div>
					<van-field type="number" v-model="data.water_account" label="水费开户号" placeholder="水费开户号 (选填)" />
					<van-field type="number" v-model="data.electric_account" label="电费开户号" placeholder="电费开户号 (选填)" />
					<van-field type="number" v-model="data.gas_account" label="煤气开户号" placeholder="煤气开户号 (选填)" />
					<div class="upload-idcard van-hairline--bottom">
						<div class="upload-idcard-item">
							<van-uploader v-model="show.house_photo" :after-read="uploadHouse" :upload-icon="require('@/assets/img/img11.png')" :max-count="1" :preview-options="{closeable: true}" />
							<p>房屋照片</p>
						</div>
						<div class="upload-idcard-item">
							<van-uploader v-model="show.scene_photo" capture :after-read="uploadScene" :upload-icon="require('@/assets/img/img10.png')" :max-count="1" :preview-options="{closeable: true}" />
							<p>本人照片</p>
						</div>
					</div>
					<van-field v-model="data.remark" rows="2" autosize label="备注" type="textarea" maxlength="255" placeholder="请输入备注(选填)" show-word-limit />
					<div class="guarantee-preview" v-show="show.guaranty">
						<p class="guarantee-title">保证书</p>
						<div class="guarantee-image-wrap van-hairline--surround">
							<img class="guarantee-image" :src="show.guaranty" @click="guaranteePreview()" alt="">
						</div>
						<p class="guarantee-reset" @click="resetSignature()">签名有误？重新签名</p>
					</div>
					<div class="submit" id="submit">
						<van-button block type="info" @click="onSubmit">提交</van-button>
					</div>
				</van-form>
			</div>
		</div>

		<van-dialog v-model="show.guaranteeShow" title="信州区房屋出租治安责任保证书" show-cancel-button @confirm="guaranteeConfirm">
			<Guarantee :fullname="manager.fullname" :id-number="manager.id_number" :address="detailAddress" />
		</van-dialog>

		<van-popup v-model="show.signatureShow" round position="bottom" :style="{ height: '80%' }" closeable>
			<div class="signature">
				<div class="signature-head">
					请您在方框内签名
				</div>
				<div class="signature-body">
					<canvas class="canvas"></canvas>
				</div>
				<div class="signature-footer">
					<van-button type="default" size="normal" round @click="signatureClear()">重置</van-button>
					<van-button type="info" size="normal" round @click="signatureSave()">保存</van-button>
				</div>
			</div>
		</van-popup>
		<Preloading :loading="preloading" />
	</div>
</template>

<script>
	import Config from '@/config/config'
	import SignaturePad from 'signature_pad'
	import trimCanvas from 'trim-canvas'
	import Utils from "@/utils/util"
	import Vant from "@/vender/vant"
	import Validator from "we-validator"
	import Compressor from 'compressorjs'
	import Storage from '@/storage/storage'
	import Preloading from "@/components/Preloading"
	import Guarantee from "@/components/Guarantee.vue"

	let currentDate = new Date();
	let minDate = new Date(currentDate.getFullYear() - 70, currentDate.getMonth(), currentDate.getDate());
	let maxDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

	export default {
		data() {
			return {
				detail: {
					address: ""
				},
				signaturePad: null,
				preloading: true,
				show: {
					guaranty: "",
					house_photo: [],
					scene_photo: [],
					signatureContent: "",
					signatureShow: false,
					guaranteeShow: false,
				},
				data: {
					token: this.$route.params.token,
					purpose: 1,
					house_photo: "",
					scene_photo: "",
					guaranty: "",
					water_account: "",
					electric_account: "",
					gas_account: "",
					remark: "",
				},
				manager: "",
				original: ""
			};
		},
		created() {
			this.manager = Storage.local.get("USER")
			// 初始化验证器
			this.validatorInit()
			// 初始化保证书内容
			this.show.signatureContent = Config.GUARANTEE

			// 原房屋数据
			this.$axios.post('/proxy/send', {
				params: {
					url: "/transfer/original"
				},
				data: {
					token: this.data.token
				}
			}).then(result => {
				if (result.code == 2000) {
					this.original = result.data;
					this.data.purpose = this.original.purpose;
					this.data.water_account = this.original.water_account;
					this.data.electric_account = this.original.electric_account;
					this.data.gas_account = this.original.gas_account;

					this.preloading = false;
				} else {
					this.$toast.fail(result.msg);
				}
			});
		},
		computed: {
			detailAddress() {
				let address = this.original.address
				if (this.original.btype == 3) {
					address += this.original.room_num
				} else {
					address += this.original.build_num + "栋"
					address += this.original.unit_num + "单元"
					address += this.original.room_num + "号"
				}
				return address
			}
		},
		methods: {
			onSubmit() {
				let data = this.data

				data.house_photo = this.getUploadImage(this.show.house_photo)
				data.scene_photo = this.getUploadImage(this.show.scene_photo)

				let result = this.validator.checkData(data, result => {
					this.$toast(result.msg);
				})

				if (false == result) {
					return false
				}

				// 签保证书
				if (!Validator.checkValue('required', data.guaranty)) {
					return this.show.guaranteeShow = true;
				}

				this.$toast.loading({
					message: '正在提交...'
				});
				this.$axios.post("/proxy/send", {
					params: {
						url: "/transfer/confirm"
					},
					data: data
				}).then((res) => {
					if (res.code == 2000) {
						this.$toast.success("过户成功")
						this.$router.replace({
							name: 'Index'
						});
					} else {
						this.$toast.fail(res.msg);
					}
				});
			},
			// 房屋照片上传
			uploadHouse(file) {
				Utils.upload(file, 'house_photo', (result) => {
					this.data.house_photo = result.relpath;
				})
			},
			// 场景照片上传
			uploadScene(file) {
				Utils.upload(file, 'scene_photo', (result) => {
					this.data.scene_photo = result.relpath;
				})
			},
			// 确认保证书内容
			guaranteeConfirm() {
				this.show.signatureShow = true;
				if (this.signaturePad) {
					return false;
				}
				setTimeout(() => {
					let canvas = this.$el.querySelector(".canvas");
					let signatureBody = this.$el.querySelector(".signature-body");
					canvas.width = signatureBody.clientWidth;
					canvas.height = signatureBody.clientHeight;
					this.signaturePad = new SignaturePad(canvas);
				}, 300);
			},
			// 保存签名
			signatureSave() {
				if (this.signaturePad.isEmpty()) {
					return this.$toast("签名不能为空")
				}
				this.$toast.loading({
					message: "生成中"
				})
				// 去除签名周围空白
				let copy = document.createElement('canvas');
				let signaturePad = this.$el.querySelector(".canvas");
				// 重新设置签名比例
				copy.width = signaturePad.width;
				copy.height = signaturePad.height;
				// 划入签名
				copy.getContext('2d').drawImage(signaturePad, 0, 0, copy.width, copy.height);
				// 去除签名边上空白
				trimCanvas(copy);

				let data = {
					base64: copy.toDataURL("image/png"),
					fullname: this.manager.fullname,
					id_number: this.manager.id_number
				}

				data.address = this.original.address;
				if (this.original.btype == 3) {
					data.address += this.original.room_num
				} else {
					data.address += this.original.build_num + "栋"
					data.address += this.original.unit_num + "单元"
					data.address += this.original.room_num + "号"
				}

				this.$axios.post("/proxy/send", {
					params: {
						url: "/guaranty/create"
					},
					data: data
				}).then((res) => {
					if (res.code == 2000) {
						this.data.guaranty = res.data.relpath;
						this.show.guaranty = res.data.url;
						setTimeout(() => {
							this.$toast.clear();
							window.scrollTo({
								'top': document.querySelector('#submit').offsetTop - 800,
								'behavior': 'smooth'
							})
						}, 100);
					} else {
						this.$toast.fail('保证书生成失败');
					}

				});
				this.show.signatureShow = false;
			},
			// 重新签名
			resetSignature() {
				this.show.signatureShow = true;
			},
			// 清空签名
			signatureClear() {
				this.signaturePad.clear();
			},
			// 保证书预览
			guaranteePreview() {
				if (Utils.isEmpty(this.show.guaranty)) {
					return this.$toast("暂无图片可预览");
				}
				Vant.ImagePreview({
					images: [this.show.guaranty],
					closeable: true,
				});
			},
			getUploadImage(data) {
				let result = [];
				for (let i = 0; i < data.length; i++) {
					if (!Utils.isEmpty(data[i].relpath)) {
						result.push(data[i].relpath)
					}
				}
				return result.length == 1 ? result[0] : result
			},
			validatorInit() {
				// 初始化验证器
				this.validator = new Validator({
					rules: {
						house_photo: {
							required: true
						},
						scene_photo: {
							required: true
						},
						water_account: {
							length: 10,
							digits: true
						},
						electric_account: {
							length: 10,
							digits: true
						},
						gas_account: {
							length: 10,
							digits: true
						},
					},
					messages: {
						house_photo: {
							required: "请上传房屋照片"
						},
						scene_photo: {
							required: "请上传本人照片"
						},
						water_account: {
							length: "水费开户号不正确",
							digits: "水费开户号必须为数字"
						},
						electric_account: {
							length: "电费开户号不正确",
							digits: "电费开户号必须为数字"
						},
						gas_account: {
							length: "煤气开户号不正确",
							digits: "煤气开户号必须为数字"
						},
					}
				})
			},
		},
		components: {
			Preloading,
			Guarantee
		}
	}
</script>

<style lang="less" scoped>
	@import "./../../assets/less/form.less";

	.declare {
		background-color: #F2F2F2;
		padding-top: 50px;

		.upload-example {
			img {
				width: 135px;
			}
		}

		.upload-tips {
			margin: 0 10px;
			padding: 10px;
			background-color: #fffbe8;
			font-size: 14px;
			color: #ed6a0c;
		}

		.upload-idcard {
			padding-bottom: 5px;
		}

		.signature {
			position: relative;
			height: 100%;
			box-sizing: border-box;
			width: 100%;
			padding: 15px;

			.signature-head {
				text-align: center;
				color: #999;
			}

			.signature-body {
				border: 1px solid #eee;
				margin: 15px 0;
				height: calc(100% - 95px);
			}

			.signature-footer {
				width: 100%;
				position: absolute;
				bottom: 15px;
				left: 0;
				display: flex;

				.van-button {
					flex: 1;
					margin: 0 10px;
				}
			}
		}

		.guarantee-preview {
			padding: 0 15px 15px;
			box-sizing: border-box;

			.guarantee-title {
				font-size: 16px;
				line-height: 32px;
				margin: 8px 0 5px;
				color: #666;
			}


			.guarantee-image-wrap {
				padding: 1px;

				.guarantee-image {
					width: 100%;
					object-fit: cover;
					display: block;
				}
			}

			.guarantee-reset {
				text-align: right;
				font-size: 14px;
				color: rgba(23, 114, 209, 0.6);
				margin: 5px 0 0;
			}
		}
	}
</style>
