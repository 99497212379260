<template>
	<div class="declare container">
		<van-nav-bar title="流动人口登记" left-arrow @click-left="back()" fixed />
		<van-form class="form" @submit="onSubmit">
			<div class="card">
				<div class="card-head van-hairline--bottom">
					<h3>房东信息</h3>
				</div>
				<div class="card-body">
					<div class="form">
						<van-field required v-model="data.landlordFullname" label="房东姓名" placeholder="请输入房东姓名" />
						<van-field required v-model="data.landlordTelephone" label="房东电话" placeholder="请输入房东电话" />
					</div>
				</div>
			</div>
			<div class="card">
				<div class="card-head van-hairline--bottom">
					<h3>租客信息</h3>
				</div>
				<div class="card-body">
					<van-field required v-model="data.tenantFullname" label="租客姓名" placeholder="请输入租客姓名" />
					<van-field required v-model="data.tenantIdNumber" label="身份证号码" placeholder="请输入身份证号码" />
					<van-field required v-model="data.tenantTelephone" label="联系电话" placeholder="请输入联系电话" />
					<van-field required v-model="data.tenantAddress" label="现住地址" placeholder="请输入现住地址" />
					<van-field required v-model="data.unitAddress" label="工作单位" placeholder="请输入工作单位" />
					<van-field required label="随行人员">
						<template #input>
							<van-radio-group v-model="data.hasEntourage" direction="horizontal">
								<van-radio name="1">有</van-radio>
								<van-radio name="2">无</van-radio>
							</van-radio-group>
						</template>
					</van-field>
					<van-field required label="核酸检测是否">
						<template #input>
							<van-radio-group v-model="data.isNucleic" direction="horizontal">
								<van-radio name="1">是</van-radio>
								<van-radio name="2">否</van-radio>
							</van-radio-group>
						</template>
					</van-field>
					<van-field required label="现场照片">
						<template #input>
							<van-uploader :after-read="uploadEnrollment" v-model="data.tenantPhoto" :preview-options="{closeable: true}" :max-count="1" />
						</template>
					</van-field>
				</div>
			</div>
			<div class="card" v-for="(item, key) in entourage" v-show="data.hasEntourage == '1'">
				<div class="card-head van-hairline--bottom">
					<h3>随行人员</h3>
				</div>
				<div class="card-body">
					<van-field required v-model="item.fullname" label="人员姓名" placeholder="请输入人员姓名" />
					<van-field required v-model="item.id_number" label="身份证号码" placeholder="请输入身份证号码" />
					<van-field required v-model="item.telephone" label="联系电话" placeholder="请输入联系电话" />
					<van-field required label="核酸检测是否">
						<template #input>
							<van-radio-group v-model="item.is_nucleic" direction="horizontal">
								<van-radio name="1">是</van-radio>
								<van-radio name="2">否</van-radio>
							</van-radio-group>
						</template>
					</van-field>
					<van-field required label="本人照片">
						<template #input>
							<van-uploader :after-read="uploadEnrollment" v-model="item.photo" :preview-options="{closeable: true}" :max-count="1" />
						</template>
					</van-field>
				</div>
				<div class="add">
					<div class="add-flex van-hairline--top">
						<div class="add-item add-create" @click="createEntourage">
							<van-icon name="add-o" />添加
						</div>
						<div class="add-item add-delete" v-show="entourage.length > 1" @click="deletePerson(key)">
							<van-icon name="close" />删除
						</div>
					</div>
				</div>
			</div>
			<div class="submit">
				<van-button block type="info" native-type="submit">提交</van-button>
			</div>
		</van-form>
	</div>
</template>

<script>
	import Validator from "we-validator"
	import Utils from "@/utils/util"

	export default {
		data() {
			return {
				data: {
					landlordFullname: "",
					landlordTelephone: "",
					tenantFullname: "",
					tenantIdNumber: "",
					tenantTelephone: "",
					tenantAddress: "",
					unitAddress: "",
					tenantPhoto: "",
					isNucleic: "2",
					hasEntourage: "2",
					tenantPhoto: []
				},
				entourage: []
			};
		},
		created() {
			this.validatorInit()
			this.createEntourage()
		},
		methods: {
			createEntourage() {
				this.entourage.push({
					fullname: "",
					id_number: "",
					telephone: "",
					photo: [],
					is_nucleic: "2"
				})
			},
			deletePerson(key) {
				this.entourage.splice(key, 1)
			},
			onSubmit() {
				let data = {
					landlord_fullname: this.data.landlordFullname,
					landlord_telephone: this.data.landlordTelephone,
					tenant_fullname: this.data.tenantFullname,
					tenant_id_number: this.data.tenantIdNumber,
					tenant_telephone: this.data.tenantTelephone,
					tenant_address: this.data.tenantAddress,
					unit_address: this.data.unitAddress,
					tenant_photo: this.data.tenantPhoto,
					is_nucleic: this.data.isNucleic,
				}
				
				data.tenant_photo = this.getUploadImage(data.tenant_photo)

				let result = this.validator.checkData(data, result => {
					this.$toast(result.msg);
				})

				if (false == result) {
					return false
				}

				data.entourage = []

				if (this.data.hasEntourage === "1") {
					for (let key in this.entourage) {
						let temp = {
							fullname: this.entourage[key].fullname,
							id_number: this.entourage[key].id_number,
							telephone: this.entourage[key].telephone,
							photo: this.getUploadImage(this.entourage[key].photo),
							is_nucleic: this.entourage[key].is_nucleic,
						}

						let result = this.entourageValidator.checkData(this.entourage[key], result => {
							this.$toast(result.msg);
						})

						if (false == result) {
							return false
						}

						data.entourage.push(temp)
					}
				}

				this.$toast.loading({
					message: "正在提交..."
				})

				this.$axios.post("/proxy/send", {
					params: {
						url: "/enrollment/create"
					},
					data: data
				}).then((result) => {
					if (result.code == 2000) {
						this.$toast.success({
							message: result.msg,
							forbidClick: true,
							duration: 1000,
							onClose: () => {
								this.$router.go(-1)
							}
						})
					} else {
						this.$toast.fail(result.msg)
					}
				})
			},
			uploadEnrollment(file) {
				Utils.upload(file, "enrollment")
			},
			getUploadImage(images) {
				return images.length > 0 && !Utils.isEmpty(images[0].relpath) ? images[0].relpath : ""
			},
			validatorInit() {
				// 初始化验证器
				this.validator = new Validator({
					rules: {
						landlord_fullname: {
							required: true
						},
						landlord_telephone: {
							required: true,
							mobile: true,
						},
						tenant_fullname: {
							required: true
						},
						tenant_id_number: {
							required: true,
							idcard: true,
						},
						tenant_telephone: {
							required: true,
							mobile: true,
						},
						tenant_address: {
							required: true
						},
						unit_address: {
							required: true
						},
						tenant_photo: {
							required: true
						}
					},
					messages: {
						landlord_fullname: {
							required: "请输入房东姓名"
						},
						landlord_telephone: {
							required: "请输入房东电话",
							mobile: "房东电话不正确",
						},
						tenant_fullname: {
							required: "请输入租客姓名",
						},
						tenant_id_number: {
							idcard: "租客姓身份证号码不正确",
							required: "请输入租客身份证号码"
						},
						tenant_telephone: {
							required: "请输入租客电话",
							mobile: "租客电话不正确",
						},
						tenant_address: {
							required: "请输入租客现住址"
						},
						unit_address: {
							required: "请输入工作单位"
						},
						tenant_photo: {
							required: "请上传本人照片"
						}
					}
				})

				this.entourageValidator = new Validator({
					rules: {
						fullname: {
							required: true
						},
						id_number: {
							idcard: true,
							required: true
						},
						telephone: {
							mobile: true,
							required: true
						},
						photo: {
							required: true
						}
					},
					messages: {
						fullname: {
							required: "请输入随行人员姓名"
						},
						id_number: {
							required: "请输入随行人员身份证号码",
							idcard: "随行人员身份证号码不正确"
						},
						telephone: {
							required: "请输入随行人员联系电话",
							mobile: "随行人员联系电话不正确",
						},
						photo: {
							required: "请上传随行人员本人照片"
						}
					}
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	@import "@/assets/less/form.less";

	.declare {
		background-color: #F2F2F2;
		padding-top: 54px;
		box-sizing: border-box;

		.add {
			padding: 0 16px;

			.add-flex {
				display: flex;

				.add-item {
					flex: 1;
					font-size: 15px;
					height: 40px;
					line-height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.add-create {
					color: #1772D1;
				}

				.add-delete {
					color: #FF0000;
				}
			}
		}


		.submit {
			padding-bottom: 40px;
		}
	}
</style>
