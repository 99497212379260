<template>
	<div class="detail container">
		<van-nav-bar title="租房详情" left-arrow @click-left="back()" fixed />
		<van-tabs v-model="tabActive" color="#1772D1">
			<van-tab title="房屋信息">
				<div class="card">
					<div class="card-head van-hairline--bottom">
						<h3>房屋信息</h3>
					</div>
					<div class="card-body">
						<div class="cell van-hairline--bottom">
							<div class="cell-title">房东姓名</div>
							<div class="cell-value">{{landlord.fullname}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">房东身份证</div>
							<div class="cell-value">{{landlord.id_number}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">房东电话</div>
							<div class="cell-value">{{landlord.tel}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">房屋类型</div>
							<div class="cell-value">{{houseTypeToText(house.btype)}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">房屋地址</div>
							<div class="cell-value">{{house.address}}</div>
						</div>
						<template v-if="house.btype != 3">
							<div class="cell van-hairline--bottom">
								<div class="cell-title">楼栋</div>
								<div class="cell-value">{{house.build_num}}</div>
							</div>
							<div class="cell van-hairline--bottom">
								<div class="cell-title">单元</div>
								<div class="cell-value">{{house.unit_num}}</div>
							</div>
							<div class="cell van-hairline--bottom">
								<div class="cell-title">房号</div>
								<div class="cell-value">{{house.room_num}}</div>
							</div>
						</template>
						<div class="cell van-hairline--bottom" v-else>
							<div class="cell-title">详细地址</div>
							<div class="cell-value">{{house.room_num}}</div>
						</div>
					</div>
				</div>
			</van-tab>
			<van-tab title="租客信息">
				<div class="card">
					<div class="card-head van-hairline--bottom">
						<h3>租客信息</h3>
					</div>
					<div class="card-body">
						<div class="cell van-hairline--bottom">
							<div class="cell-title">租客姓名</div>
							<div class="cell-value">{{tenant.fullname}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">租客身份证</div>
							<div class="cell-value">{{tenant.id_number}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">租客户籍地</div>
							<div class="cell-value">{{tenant.address}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">租客电话</div>
							<div class="cell-value">{{tenant.tel}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">工作单位</div>
							<div class="cell-value">{{tenant.work_place}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">承租日期</div>
							<div class="cell-value">{{tenant.start_date}} - {{tenant.end_date}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">租客证件</div>
							<div class="cell-value">
								<img class="cell-image" @click="preview(user.id_fp_url)" :src="user.id_fp_url" alt="">
							</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">房屋照片</div>
							<div class="cell-value">
								<img class="cell-image" @click="preview(tenant.house_photo)" :src="tenant.house_photo" alt="">
							</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">租客照片</div>
							<div class="cell-value">
								<img class="cell-image" @click="preview(tenant.scene_photo)" :src="tenant.scene_photo" alt="">
							</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">租房合同</div>
							<div class="cell-value">
								<div class="cell-value">
									<img v-for="(item, index) in tenant.renting_contract" class="cell-image" @click="preview(tenant.renting_contract, index)" :src="item" alt="">
								</div>
							</div>
						</div>
						<template v-if="tenant.warning_status >= 2">
							<div class="cell van-hairline--bottom">
								<div class="cell-title">人像检测预警</div>
								<div class="cell-value">
									<template v-if="tenant.warning_response">
										<van-tag type="danger">
											{{tenant.warning_response.exception}}
										</van-tag>
									</template>
								</div>
							</div>
							<template v-if="tenant.id_check && tenant.id_check.length >= 1">
								<div class="cell van-hairline--bottom">
									<div class="cell-title">证件检测预警</div>
									<div class="cell-value">
										<van-tag v-for="vo in tenant.id_check" type="danger">{{vo.msg}}</van-tag>
									</div>
								</div>
							</template>
						</template>
						<div class="cell cell-btn-group" v-if="tenant.warning_status != 1">
							<van-button type="primary" size="small" color="#07C160" @click="confirmWarningStatus(tenant.id, 1)">确认正常</van-button>
						</div>
					</div>
				</div>
			</van-tab>
			<van-tab title="随行人员">
				<div class="card visit" v-if="entourage.length <= 0">
					<div class="card-head van-hairline--bottom">
						<h3>随行人员</h3>
					</div>
					<div class="card-body">
						<p class="empty-tips">无记录</p>
					</div>
				</div>
				<div class="card visit" v-for="(item, key) in entourage">
					<div class="card-head van-hairline--bottom">
						<h3>随行人员</h3>
					</div>
					<div class="card-body">
						<div class="cell van-hairline--bottom">
							<div class="cell-title">人员姓名</div>
							<div class="cell-value">{{item.fullname}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">人员身份证</div>
							<div class="cell-value">{{item.id_number}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">联系电话</div>
							<div class="cell-value">{{item.tel}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">人员照片</div>
							<div class="cell-value">
								<img class="cell-image" @click="preview(item.scene_photo_url)" :src="item.scene_photo_url" alt="">
							</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">人员状态</div>
							<div class="cell-value">
								<van-tag v-if="item.status == 1" type="danger">待确认</van-tag>
								<van-tag v-if="item.status == 2" type="success">已确认</van-tag>
								<van-tag v-if="item.status == 3" type="danger">待删除</van-tag>
							</div>
						</div>
						<template v-if="item.warning_status >= 2">
							<div class="cell van-hairline--bottom">
								<div class="cell-title">人像检测预警</div>
								<div class="cell-value">
									<template v-if="item.warning_response">
										<van-tag type="danger">
											{{item.warning_response.exception}}
										</van-tag>
									</template>
								</div>
							</div>
							<template v-if="item.id_check && item.id_check.length >= 1">
								<div class="cell van-hairline--bottom">
									<div class="cell-title">证件检测预警</div>
									<div class="cell-value">
										<van-tag v-for="vo in item.id_check" type="danger">{{vo.msg}}</van-tag>
									</div>
								</div>
							</template>
						</template>
						<div class="cell cell-btn-group" v-if="item.warning_status >= 2">
							<van-button type="primary" size="small" color="#07C160" @click="confirmWarningStatus(item.id, 2, key)">确认正常</van-button>
						</div>
					</div>
				</div>
			</van-tab>
			<van-tab title="走访记录">
				<div class="card visit">
					<div class="card-head van-hairline--bottom">
						<h3>走访记录</h3>
					</div>
					<div class="card-body">
						<p class="empty-tips" v-if="visitHistory.length <= 0">无记录</p>
						<van-steps direction="vertical" :active="0" active-color="#1772D1">
							<van-step class="visit-item" v-for="item in visitHistory">
								<h3>
									走访状态：
									<van-tag v-if="item.visit_status == 1" type="success">正常</van-tag>
									<van-tag v-if="item.visit_status == 2" type="danger">异常</van-tag>
								</h3>
								<p>走访人员：{{item.nickname}}</p>
								<p v-if="item.visit_status == 2">
									异常类型：
									<span>{{visitTypeText(item.type)}}</span>
								</p>
								<p v-if="item.visit_status == 2">走访备注：{{item.remark ? item.remark : '无'}}</p>
								<p>走访时间：{{item.create_time}}</p>
							</van-step>
						</van-steps>
					</div>
				</div>
				<div class="h-50"></div>
				<div class="confirm">
					<van-button type="info" block @click="success">正常</van-button>
					<van-button type="danger" block @click="error">异常</van-button>
				</div>
				<van-popup v-model="visitShow" closeable position="bottom">
					<div class="visit-from">
						<van-field required readonly clickable @click="typeShow = true" v-model="typeText" label="异常类型" placeholder="请选择异常类型" right-icon="arrow-down" />
						<van-field v-model="data.remark" rows="2" autosize label="留言" type="textarea" maxlength="50" placeholder="请输入备注" show-word-limit />
						<van-button block type="info" @click="submit">提交</van-button>
					</div>
				</van-popup>
				<van-popup v-model="typeShow" position="bottom">
					<van-picker title="请选择异常类型" show-toolbar :columns="visitType" @confirm="onConfirm" @cancel="typeShow = false" />
				</van-popup>
			</van-tab>
		</van-tabs>

		<Preloading :loading="preloading" />
	</div>
</template>

<script>
	import Vant from "@/vender/vant"
	import Utils from '@/utils/util'
	import Preloading from "@/components/Preloading"

	export default {
		name: "SuperviseTenantDetail",
		data() {
			return {
				user: "",
				house: "",
				tenant: "",
				landlord: "",
				visitType: [],
				typeText: "",
				typeShow: false,
				visitShow: false,
				preloading: true,
				tabActive: 0,
				entourage: [],
				visitHistory: [],
				data: {
					type: "",
					visit_status: "",
					remark: "",
					address: "",
					longitude: '',
					latitude: '',
				}
			}
		},
		created() {
			Utils.wechat(() => {
				this.getLocation();
			});

			this.$axios.post("/setting/getVisitType").then((result) => {
				if (result.code == 2000) {
					this.visitType = result.data
				} else {
					this.$toast.fail(result.msg);
				}
			});

			let params = this.$route.params;
			this.$axios.post('/proxy/send', {
				params: {
					url: "/supervise/tenantDetail"
				},
				data: {
					id: params.id
				}
			}).then((result) => {
				if (result.code == 2000) {
					this.user = result.data.user
					this.house = result.data.house
					this.tenant = result.data.tenant
					this.landlord = result.data.landlord
					this.entourage = result.data.entourage
					this.visitHistory = result.data.visit_history

					// 数据格式处理
					this.tenant.start_date = Utils.formatDate(new Date(this.tenant.start_date), '/')
					this.tenant.end_date = Utils.formatDate(new Date(this.tenant.end_date), '/')

					this.preloading = false
				} else {
					this.$toast.fail(result.msg);
				}
			});
		},
		methods: {
			visitTypeText(type) {
				for (let i = 0; i < this.visitType.length; i++) {
					if (this.visitType[i].id == type) {
						return this.visitType[i].text
					}
				}
				return "未知类型"
			},
			success() {
				this.$dialog.confirm({
					title: '提示',
					message: '确认信息正常无误吗？',
				}).then(() => {
					this.data.visit_status = 1;
					this.submit();
				});
			},
			error() {
				this.visitShow = true;
				this.data.visit_status = 2;
			},
			submit() {
				let data = {
					id: this.$route.params.id,
					address: this.data.address,
					latitude: this.data.latitude,
					longitude: this.data.longitude,
					visit_status: this.data.visit_status,
				}
				if (this.data.visit_status == 1) {
					data.type = "";
					data.remark = "";
				} else {
					if (Utils.isEmpty(this.data.type)) {
						return this.$toast("请选择异常类型");
					}
					data.type = this.data.type;
					data.remark = this.data.remark;
				}

				// 如果没有取到则默认位置
				data.latitude = data.latitude || 28.430483;
				data.longitude = data.longitude || 117.965916;

				this.$axios.post('/proxy/send', {
					params: {
						url: "/supervise/visitConfirm"
					},
					data: data
				}).then((res) => {
					if (res.code == 2000) {
						this.$store.commit("setData", {
							type: 'update',
							info: {
								id: data.id,
								visit_status: data.visit_status
							}
						});
						this.$toast.success("提交成功");
						this.$router.go(-1);
					} else {
						this.$toast.fail(res.msg);
					}
				});
			},
			getLocation() {
				wx.getLocation({
					type: 'wgs84',
					success: (res) => {
						let data = {
							latitude: res.latitude,
							longitude: res.longitude,
						}
						// GPS转腾讯地图坐标
						this.$axios.post("/we_chat/location", data).then((res) => {
							if (res.code == 2000) {
								this.data.address = res.data.address;
								this.data.latitude = res.data.location.lat;
								this.data.longitude = res.data.location.lng;
							} else {
								this.$toast.fail(res.msg);
							}
						});
					}
				});
			},
			confirmWarningStatus(id, type, key) {
				this.$dialog.confirm({
					title: '提示',
					message: '确认租客信息预警状态正常吗？',
				}).then(() => {
					this.$toast.loading({
						message: "正在提交"
					})
					// GPS转腾讯地图坐标
					this.$axios.post("/proxy/send", {
						params: {
							url: "/supervise/confirmWarningStatus"
						},
						data: {
							id: id,
							type: type
						}
					}).then((res) => {
						if (res.code == 2000) {
							if (type == 1) {
								this.tenant.warning_status = 1
							} else {
								this.entourage[key].warning_status = 1
							}
							this.$toast.success("确认成功");
						} else {
							this.$toast.fail(res.msg);
						}
					});
				});
			},
			onConfirm(value) {
				this.typeText = value.text;
				this.data.type = value.id;
				this.typeShow = false;
			},
			preview(images) {
				if (!(images instanceof Array)) {
					images = images.split(",");
				}
				Vant.ImagePreview({
					images: images,
					closeable: true,
				});
			},
			houseTypeToText(type){
				return Utils.houseTypeToText(type)
			}
		},
		components: {
			Preloading
		}
	}
</script>

<style lang="less" scoped>
	.detail {
		background-color: #F2F2F2;
		padding-top: 49px;

		.card .van-tag {
			margin-right: 5px;
		}

		.card-body {
			padding: 0 10px 10px;
		}

		.confirm {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			display: flex;

			button {
				flex: 1;
				border-radius: 0;
				font-size: 18px;
			}
		}

		.visit-from {
			background-color: #f7f8fa;
			width: 100%;
			min-height: 100%;
			padding: 50px 10px 10px;
			box-sizing: border-box;
			
			/deep/.van-cell__title{
				font-size: 18px;
			}
			
			/deep/.van-field__control{
				font-size: 18px;
			}
			
			button {
				margin-top: 30px;
			}
		}

		.visit {

			.visit-item {
				
				h3 {
					font-size: 18px;
					font-weight: normal;
					margin: 0;
				}

				p {
					font-size: 18px;
					color: #999;
					margin: 15px 0;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
		
		span.van-tag{
			font-size: 16px;
			line-height: 1.2;
		}
	}
</style>
