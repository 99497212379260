<template>
	<div class="house container" ref="scroll">
		<van-nav-bar title="房屋列表" left-arrow @click-left="back()" @click-right="showSearch" fixed>
			<template #right>
				<van-icon class="search-btn" name="search" />
			</template>
		</van-nav-bar>

		<div class="fixed-top">
			<van-dropdown-menu active-color="#1772D1">
				<van-dropdown-item v-model="params.order" :options="orderOption" @change="reload" />
				<van-dropdown-item v-model="params.warning_status" :options="warningOption" @change="reload" />
				<van-dropdown-item v-model="params.visit_timeout" :options="visitTimeoutOption" @change="reload" />
				<van-dropdown-item v-model="params.visit_status" :options="visitStatusOption" @change="reload" />
			</van-dropdown-menu>
		</div>

		<van-list v-model="loading" :finished="finished" finished-text="没有更多啦" @load="loadData">
			<div class="card">
				<div class="card-head van-hairline--bottom">
					<h3><span>房屋列表</span></h3>
				</div>
				<div class="card-body">
					<div class="house-wrap cell-list-wrap">
						<div class="cell-list" v-for="item in house" @click="$router.push({name: 'SuperviseTenant', params:{id: item.id}})">
							<div class="cell-list-item">
								<van-icon name="user-o" />
								<div class="cell-list-item-title">房东姓名：</div>
								<div class="cell-list-item-text">{{item.fullname}}</div>
							</div>
							<div class="cell-list-item">
								<van-icon name="phone-o" />
								<div class="cell-list-item-title">联系电话：</div>
								<div class="cell-list-item-text">{{item.tel}}</div>
							</div>
							<div class="cell-list-item">
								<van-icon name="location-o" />
								<div class="cell-list-item-title">房屋地址：</div>
								<div class="cell-list-item-text">
									<span class="cell-wrap-text">{{item.address}}</span>
								</div>
							</div>
							<div class="cell-list-item">
								<van-icon name="hotel-o" />
								<div class="cell-list-item-title">详细楼层：</div>
								<div class="cell-list-item-text">{{address(item)}}</div>
							</div>
							<div class="cell-list-item">
								<van-icon name="clock-o" />
								<div class="cell-list-item-title">录入时间：</div>
								<div class="cell-list-item-text">{{item.create_time}}</div>
							</div>
							<div class="cell-list-item">
								<van-icon name="bulb-o" />
								<div class="cell-list-item-title">预警状态：</div>
								<div class="cell-list-item-text">
									<span class="cell-wrap-text">
										<i v-if="item.warning_status == 1" class="text-color text-color-green">正常</i>
										<i v-if="item.warning_status == 2" class="text-color text-color-red">异常</i>
										<i v-if="item.warning_status == 3" class="text-color text-color-red">人脸检测异常</i>
									</span>
								</div>
							</div>
							<div class="cell-list-item">
								<van-icon name="records" />
								<div class="cell-list-item-title">走访状态：</div>
								<div class="cell-list-item-text">
									<span class="cell-wrap-text">
										<i v-if="item.visit_status == 0" class="text-color text-color-gray">未走访</i>
										<i v-if="item.visit_status == 1" class="text-color text-color-green">正常</i>
										<i v-if="item.visit_status == 2" class="text-color text-color-red">异常</i>
									</span>
								</div>
							</div>
							<div class="cell-list-item">
								<van-icon name="clock-o" />
								<div class="cell-list-item-title">走访时间：</div>
								<div class="cell-list-item-text">
									<span v-if="item.visit_time">{{item.visit_time}}</span>
									<span v-else>无</span>
								</div>
							</div>
							<div class="status status-error" v-if="item.visit_timeout == 1">
								<i class="text-color text-color-red">走访超时</i>
							</div>
						</div>
					</div>
				</div>
			</div>
		</van-list>
		<div class="empty" v-if="preloading == false && house.length <= 0">
			<van-empty description="未查询到相关数据" />
		</div>
		<Preloading :loading="preloading" />
		<Search v-show="search" />
	</div>
</template>

<script>
	import Utils from "@/utils/util"
	import Search from "@/views/supervise/Search"
	import Preloading from "@/components/Preloading"

	const keepAlive = ["SuperviseSearch", "SuperviseTenant", "SuperviseHouseDetail"];

	const orderOption = [{
		text: '默认排序',
		value: ""
	}, {
		text: '最新录入',
		value: 'desc'
	}, {
		text: '最早录入',
		value: 'asc'
	}]

	const warningOption = [{
		text: '预警状态',
		value: ""
	}, {
		text: '正常',
		value: 1
	}, {
		text: '异常',
		value: 2
	}]

	const visitTimeoutOption = [{
		text: '走访超时',
		value: ""
	}, {
		text: '未超时',
		value: 1
	}, {
		text: '已超时',
		value: 2
	}]

	const visitStatusOption = [{
		text: '走访结果',
		value: ""
	}, {
		text: '未走访',
		value: 0
	}, {
		text: '正常',
		value: 1
	}, {
		text: '异常',
		value: 2
	}]

	export default {
		name: "SuperviseHouse",
		data() {
			return {
				house: [],
				search: false,
				loading: false,
				finished: false,
				preloading: true,
				params: {
					id: this.$route.params.id || '',
					jd_id: "",
					jdb_id: "",
					sc_id: "",
					order: "desc",
					start: 0,
					limit: 10,
					visit: "",
					keywords: "",
					visit_status: "",
					visit_timeout: "",
					warning_status: ""
				},
				orderOption: orderOption,
				warningOption: warningOption,
				visitStatusOption: visitStatusOption,
				visitTimeoutOption: visitTimeoutOption,
			}
		},
		methods: {
			loadData() {
				this.$axios.post('/proxy/send', {
					params: {
						url: "/supervise/houseList"
					},
					data: this.params
				}).then(res => {
					if (res.code == 2000) {
						this.params.start = this.house.length;
						this.house = this.house.concat(res.data.list);
						if (this.house.length >= res.data.total) {
							this.finished = true;
						}
						this.loading = false;
						this.preloading = false;
					} else {
						this.$toast(res.msg);
					}
				});
			},
			reload() {
				this.house = [];
				this.preloading = true;
				this.params.start = 0;
				this.loadData();
			},
			// 完整地址
			address(data) {
				return Utils.detailAddress(data)
			},
			showSearch() {
				this.search = true
			},
			hideSearch() {
				this.search = false
			}
		},
		components: {
			Search,
			Preloading
		},
		beforeRouteEnter(to, from, next) {
			next((vm) => {
				vm.$keepAlive.enter(to, from, vm, keepAlive);
			});
		},
		beforeRouteLeave(to, from, next) {
			this.$keepAlive.leave(to, from, this, keepAlive);
			next();
		}
	}
</script>

<style lang="less" scoped>
	.house {
		background-color: #F2F2F2;
		padding-top: 98px;

		.search-btn {
			font-size: 18px;
		}

		.fixed-top {
			position: fixed;
			top: 49px;
			left: 0;
			width: 100%;
			z-index: 100;
		}

		.empty {
			padding-top: 148px;
		}

		.card-head h3 {
			display: flex;
			justify-content: space-between;

			&:last-child {
				padding-right: 10px;
			}
		}

		.house-wrap {
			padding: 0;

			.cell-list {
				margin-bottom: 1px;
				padding: 10px 8px;
				border-bottom: 10px solid #F2F2F2;

				.status {
					width: 60px;
					height: 60px;
					position: absolute;
					right: 0;
					bottom: 0;
					overflow: hidden;

					i {
						position: absolute;
						width: 200%;
						height: 200%;
						right: -120%;
						bottom: -120%;
						transform: rotate(-45deg);
						text-align: center;
						font-size: 11px;
						font-style: normal;
						line-height: 30px;
					}

					&.status-success i {
						background-color: rgba(23, 114, 209, 0.1);
						color: #1772D1;
					}

					&.status-error i {
						background-color: rgba(255, 0, 0, 0.1);
						color: #FF0000;
					}
				}
			}
		}
	}
</style>
