import Vue from 'vue'

import {
	Button,
	Grid,
	GridItem,
	Cell,
	CellGroup,
	NavBar,
	Form,
	Field,
	Uploader,
	Cascader,
	Popup,
	Calendar,
	Dialog,
	Toast,
	Picker,
	RadioGroup,
	Radio,
	DatetimePicker,
	List,
	Empty,
	Loading,
	Icon,
	DropdownMenu,
	DropdownItem,
	Tag,
	Tab,
	Tabs,
	Step,
	Steps,
	ImagePreview,
	NoticeBar
} from 'vant';


Vue.use(Button);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(NavBar);
Vue.use(Form);
Vue.use(Field);
Vue.use(Uploader);
Vue.use(Cascader);
Vue.use(Calendar);
Vue.use(Dialog);
Vue.use(Popup);
Vue.use(Toast);
Vue.use(Picker);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(DatetimePicker);
Vue.use(List);
Vue.use(Empty);
Vue.use(Loading);
Vue.use(Icon);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Tag);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Step);
Vue.use(Steps);
Vue.use(ImagePreview);
Vue.use(NoticeBar);

// 加载等待禁止操作
Toast.setDefaultOptions('loading', {
	forbidClick: true,
});

Dialog.setDefaultOptions({
	theme: "round",
	confirmButtonColor: "#1772D1"
});

export default {
	Dialog,
	Toast,
	ImagePreview
}
