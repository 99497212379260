<template>
	<div class="detail container">
		<van-nav-bar title="租房详情" left-arrow @click-left="back()" fixed />
		<van-tabs v-model="tabActive" color="#1772D1">
			<van-tab title="房屋详情">
				<div class="card">
					<div class="card-head van-hairline--bottom">
						<h3>房屋详情</h3>
					</div>
					<div class="card-body">
						<div class="cell van-hairline--bottom">
							<div class="cell-title">房东姓名</div>
							<div class="cell-value">{{landlord.fullname}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">房东电话</div>
							<div class="cell-value">{{landlord.tel}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">所属区域</div>
							<div class="cell-value">{{area_text}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">房屋地址</div>
							<div class="cell-value">{{house.address}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">详细楼层</div>
							<div class="cell-value">{{detailAddress(house)}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">水费开户号</div>
							<div class="cell-value">{{house.water_account}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">电费开户号</div>
							<div class="cell-value">{{house.electric_account}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">燃气开户号</div>
							<div class="cell-value">{{house.gas_account}}</div>
						</div>
					</div>
				</div>

			</van-tab>
			<van-tab title="租客信息">
				<div class="card">
					<div class="card-head van-hairline--bottom">
						<h3>租客信息</h3>
					</div>
					<div class="card-body">
						<div class="cell van-hairline--bottom">
							<div class="cell-title">租客姓名</div>
							<div class="cell-value">{{tenant.fullname}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">租客身份证</div>
							<div class="cell-value">{{tenant.id_number}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">租客电话</div>
							<div class="cell-value">{{tenant.tel}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">房屋照片</div>
							<div class="cell-value">
								<img class="cell-image" @click="preview(tenant.house_photo_url)" :src="tenant.house_photo_url" alt="">
							</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">场景照片</div>
							<div class="cell-value">
								<img class="cell-image" @click="preview(tenant.scene_photo_url)" :src="tenant.scene_photo_url" alt="">
							</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">租房合同</div>
							<div class="cell-value">
								<div class="cell-value">
									<img v-for="(item, index) in tenant.renting_contract_url" class="cell-image" @click="preview(tenant.renting_contract_url, index)" :src="item" alt="">
								</div>
							</div>
						</div>
					</div>
				</div>
			</van-tab>
			<van-tab title="随行人员">
				<div class="card visit" v-for="(item, key) in entourage">
					<div class="card-head van-hairline--bottom">
						<h3>随行人员</h3>
					</div>
					<div class="card-body">
						<div class="cell van-hairline--bottom" v-if="tenant.status == 2 && tenant.is_expired == 0">
							<div class="cell-title">人员状态</div>
							<div class="cell-value">
								<van-tag v-if="item.status == 1" type="danger">待确认</van-tag>
								<van-tag v-if="item.status == 2" type="success">正常</van-tag>
								<van-tag v-if="item.status == 3" type="danger">待房东删除</van-tag>
							</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">姓名</div>
							<div class="cell-value">{{item.fullname}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">电话</div>
							<div class="cell-value">{{item.tel}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">证件号</div>
							<div class="cell-value">{{item.id_number}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">现场照片</div>
							<div class="cell-value">
								<div class="cell-value">
									<img class="cell-image" @click="preview(item.scene_photo_url)" :src="item.scene_photo_url" alt="">
								</div>
							</div>
						</div>
						<div class="cell cell-btn-group" v-if="tenant.status == 2 && item.status == 2 && tenant.is_expired == 0">
							<van-button size="small" type="danger" @click="deleteEntourage(item, key)">删除</van-button>
						</div>
					</div>
				</div>
				<div class="card visit" v-if="entourage.length <= 0">
					<div class="card-body">
						<p class="empty-tips">无记录</p>
					</div>
				</div>
			</van-tab>
		</van-tabs>
		<Preloading :loading="preloading" />
	</div>
</template>

<script>
	
	import Utils from '@/utils/util'
	import Preloading from "@/components/Preloading"

	export default {
		name: "TenantDetail",
		data() {
			return {
				house: "",
				tenant: "",
				landlord: "",
				tabActive: 0,
				area_text: "",
				entourage: "",
				preloading: true
			}
		},
		created() {
			this.tabActive = parseInt(this.$route.params.tab) || 0
			this.$axios.post('/proxy/send', {
				params: {
					url: "/tenant/detail"
				},
				data: {
					id: this.$route.params.id
				}
			}).then((result) => {
				if (result.code == 2000) {
					this.house = result.data.house
					this.tenant = result.data.tenant
					this.landlord = result.data.landlord
					this.area_text = result.data.area_text
					this.entourage = result.data.entourage
					this.preloading = false
				} else {
					this.$toast.fail(result.msg)
				}
			});
		},
		methods: {
			deleteEntourage(data, key) {
				this.$dialog.confirm({
					title: '提示',
					message: "确认删除此随行人员吗？",
				}).then(() => {
					this.$toast.loading({
						message: "正在提交"
					})
					this.$axios.post('/proxy/send', {
						params: {
							url: "/entourage/delete"
						},
						data: {
							id: data.id,
							type: 2
						}
					}).then(res => {
						if (res.code == 2000) {
							this.entourage[key]['status'] = 3
							this.$toast.success("提交成功，等待房东确认！");
						} else {
							this.$toast.fail(res.msg);
						}
					});
				});
			},
			preview(images, index) {
				Utils.preview(images, index)
			},
			detailAddress(data) {
				return Utils.detailAddress(data);
			}
		},
		components: {
			Preloading
		}
	}
</script>

<style lang="less" scoped>
	.detail {
		background-color: #F2F2F2;
		padding-top: 50px;

		.card-body {
			padding: 0 10px 10px;
		}
		
		span.van-tag{
			font-size: 16px;
			line-height: 1.2;
		}
	}
</style>
