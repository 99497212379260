import Axios from 'axios'
import Vant from "@/vender/vant"
import Config from '@/config/config';
import Compressor from 'compressorjs'

/**
 * 校验是否为空
 * @param {Object} str
 */
const isEmpty = str => {
	if (typeof str == "undefined" || str == null || str == "" || str.length <= 0) {
		return true;
	}
	return false;
}

/**
 * 校验是否为手机号
 * @param {Object} str
 */
const isMobile = str => {
	return /^1[3-9][0-9]\d{8}$/.test(str);
}

/**
 * 校验是否为身份证号
 * @param {Object} str
 */
const isIdCard = str => {
	return /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/
		.test(str);
}


/**
 * sdk初始化
 * @param {Object} ready
 * @param {Object} error
 */
const wechat = (ready, error) => {

	let params = {}

	if (Config.IOS) {
		params.url = Config.ORIGIN_URL;
	} else {
		params.url = window.location.href;
	}

	Axios.post('/we_chat/jssdk', params).then((res) => {
		if (res.code == 2000) {
			let jssdk = JSON.parse(res.data);

			let cfg = {
				debug: jssdk.debug,
				appId: jssdk.appId,
				timestamp: jssdk.timestamp,
				nonceStr: jssdk.nonceStr,
				signature: jssdk.signature,
				jsApiList: jssdk.jsApiList
			}

			wx.config(cfg);

			// 初始化失败
			wx.error((res) => {
				if (error && (error instanceof Function)) {
					error(res);
				}
			})

			// 初始化完成
			wx.ready(() => {
				if (ready && (ready instanceof Function)) {
					ready();
				}
			})
		} else {
			Vant.Toast.fail(res.msg);
		}
	});
}


const isIos = () => {
	let u = navigator.userAgent;
	if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
		return true
	} else {
		return false;
	}
}


const detailAddress = data => {
	if (data.btype == 3) {
		return data.room_num;
	} else {
		return data.build_num + "栋" + data.unit_num + "单元" + data.room_num + "号";
	}
}


const formatDate = (date, symbol = '-') => {
	let year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();

	year = year < 10 ? '0' + year : year
	month = month < 10 ? '0' + month : month
	day = day < 10 ? '0' + day : day

	return year + symbol + month + symbol + day
}


/**
 * 清除Axios全部请求
 */
const clearAxios = () => {
	__AxiosRequestCancelTokenList.forEach((item, index) => {
		item.cancel()
	})
}

/**
 * 图片压缩上传
 */
const upload = (file, name, success) => {
	file.status = 'uploading';
	file.message = '上传中';
	// 图片压缩
	new Compressor(file.file, {
		maxWidth: 800,
		quality: 0.8,
		success: (result) => {
			let data = new FormData();
			data.append('image', result, result.name);
			data.append('name', name);

			// 图片上传
			Axios.post("/upload/image", data).then((res) => {
				if (res.code == 2000) {
					file.status = 'done';
					file.message = '上传成功';
					file.content = res.data.url;
					file.relpath = res.data.relpath;

					if (typeof success == 'function') {
						success(res.data)
					}
				} else {
					file.status = 'failed';
					file.message = res.msg;
				}
			});
		},
		error() {
			Vant.Toast.msg("图片压缩失败！");
		}
	})
}


const preview = (images, index) => {
	if (!(images instanceof Array)) {
		images = images.split(",");
	}
	Vant.ImagePreview({
		images: images,
		closeable: true,
		startPosition: index || 0,
	});
}

/**
 * 房屋类型转房屋类型文本
 */
const houseTypeToText = (type) => {
	let text = "";
	switch (type) {
		case 1:
			text = "商品房"
			break;
		case 2:
			text = "公寓"
			break;
		case 3:
			text = "自建房"
			break;
		case 4:
			text = "店铺"
			break;
		default:
			text = "未知"
	}
	return text
}

/**
 * 租客扫码二维码结果
 * @return 租房提示界面完整路径
 */
const tenantScanQRCode = () => {
	return new Promise((resolve, reject) => {
		wx.scanQRCode({
			needResult: 1,
			scanType: ["qrCode"],
			success: (qrcodeResult) => {
				let result = qrcodeResult.resultStr.match(/\/prompt\/(?<token>.*?)$/);
				if (result) {
					resolve(result[0]);
				} else {
					Vant.Toast.fail("无效的二维码");
				}
			},
			error: () => {
				Vant.Toast.fail("二维码识别失败");
			}
		})
	})
}

export default {
	formatDate,
	detailAddress,
	isIos,
	wechat,
	isIdCard,
	isMobile,
	isEmpty,
	clearAxios,
	upload,
	preview,
	houseTypeToText,
	tenantScanQRCode
}
