<template>
	<div class="search container">
		<van-nav-bar title="房屋搜索" left-arrow @click-left="$parent.hideSearch" @click-right="$parent.hideSearch" fixed>
			<template #right>
				<van-icon class="search-btn" name="cross" />
			</template>
		</van-nav-bar>

		<div class="filter">
			<div class="filter-search">
				<van-field label="关键词" v-model="$parent.params.keywords" placeholder="姓名、电话、证件号" clearable />
			</div>
			<div class="filter-select">
				<h3>区域筛选</h3>
				<van-field readonly clickable :value="ps_id" label="派出所" disabled />
				<van-field readonly clickable @click="Picker1Show" :value="jd_id" label="镇街" placeholder="请选择" right-icon="arrow" />
				<van-field readonly clickable @click="Picker2Show" :value="jdb_id" label="村居" placeholder="请选择" right-icon="arrow" />
				<van-field readonly clickable @click="Picker3Show" :value="sc_id" label="小区" placeholder="请选择" right-icon="arrow" />
			</div>
			<div class="filter-btn">
				<van-button @click="search" block type="info" size="small">搜索</van-button>
				<van-button @click="reset" block type="danger" size="small">重置</van-button>
			</div>
		</div>

		<van-popup v-model="showPicker1" position="bottom" title="请选择镇街">
			<van-picker show-toolbar :columns="column1" @confirm="confirm1" @cancel="showPicker1 = false" />
		</van-popup>
		<van-popup v-model="showPicker2" position="bottom" title="请选择村居">
			<van-picker show-toolbar :columns="column2" @confirm="confirm2" @cancel="showPicker2 = false" />
		</van-popup>
		<van-popup v-model="showPicker3" position="bottom" title="请选择小区">
			<van-picker show-toolbar :columns="column3" @confirm="confirm3" @cancel="showPicker3 = false" />
		</van-popup>
	</div>
</template>

<script>
	const keepAlive = ["SuperviseHouse"];

	export default {
		name: "SuperviseSearch",
		data() {
			return {
				ps_id: "",
				jd_id: "",
				jdb_id: "",
				sc_id: "",
				showPicker1: false,
				showPicker2: false,
				showPicker3: false,
				column1: [],
				column2: [],
				column3: []
			}
		},
		created() {
			this.$axios.post('/proxy/send', {
				params: {
					url: "/supervise/getBelong"
				}
			}).then(result => {
				if (result.code == 2000) {
					this.ps_id = result.data.belong
					this.column1 = result.data.town
				} else {
					this.$toast.fail(result.msg);
				}
			});
		},
		methods: {
			search() {
				this.$parent.hideSearch()
				this.$parent.reload()
			},
			reset() {
				this.clearData("jd_id")
				this.clearData("jdb_id", "column2")
				this.clearData("sc_id", "column3")
				this.$parent.params.keywords = ""
				this.$parent.hideSearch()
				this.$parent.reload()
			},
			Picker1Show() {
				this.showPicker1 = true
			},
			Picker2Show() {
				if (!this.$parent.params.jd_id) {
					return this.$toast("请选择镇街")
				}
				if (this.column2.length <= 0) {
					return this.$toast("当前镇街下暂无村居可选")
				}
				this.showPicker2 = true
			},
			Picker3Show() {
				if (!this.$parent.params.jdb_id) {
					return this.$toast("请选择村居")
				}
				if (this.column3.length <= 0) {
					return this.$toast("当前选择村居下暂无小区可选")
				}
				this.showPicker3 = true
			},
			confirm1(value) {
				this.showPicker1 = false
				this.jd_id = value.text
				this.$parent.params.jd_id = value.id
				this.clearData("jdb_id", "column2")
				this.clearData("sc_id", "column3")
				this.getChild(value.id, "jdb_id", "column2")
			},
			confirm2(value) {
				this.showPicker2 = false
				this.jdb_id = value.text
				this.$parent.params.jdb_id = value.id
				this.clearData("sc_id", "column3")
				this.getChild(value.id, "sc_id", "column3")
			},
			confirm3(value) {
				this.showPicker3 = false
				this.sc_id = value.text
				this.$parent.params.sc_id = value.id
			},
			clearData(name, column) {
				// 清除已选择区域
				if (name) {
					this[name] = ""
					this.$parent.params[name] = ""
				}
				// 清除子级待选数据
				if (column) {
					this[column] = []
				}
			},
			getChild(parent_id, type, name) {
				this.$toast.loading({
					message: "加载中"
				})
				this.$axios.post('/proxy/send', {
					params: {
						url: "/supervise/getChild"
					},
					data: {
						type: type,
						parent_id: parent_id
					}
				}).then(result => {
					if (result.code == 2000) {
						this[name] = result.data
					} else {
						this.$toast.fail(result.msg)
					}
					this.$toast.clear()
				})
			}
		}
	}
</script>

<style lang="less">
	.search {
		position: fixed;
		top: 49px;
		left: 0;
		height: calc(100% - 49px);
		padding: 10px;
		background-color: #f2f2f2;
		box-sizing: border-box;
		z-index: 101;
		width: 100%;

		.van-cell {
			line-height: 25px;
			font-size: 15px;
		}

		.filter-search {
			margin-top: 6px;
		}

		.filter-select {
			h3 {
				font-size: 18px;
				font-weight: normal;
				color: #1772D1;
				margin: 24px 0 8px;
			}
		}

		.filter-btn {
			padding: 20px 100px 0;

			button {
				margin-bottom: 15px;
			}
		}
	}
</style>
