import Receive from '@/views/receive/Receive.vue'
import ReceiveHouse from '@/views/receive/House.vue'
import ReceiveLocal from '@/views/receive/Local.vue'
import ReceivePrompt from '@/views/receive/Prompt.vue'
import ReceiveSuccess from '@/views/receive/Success.vue'

const receive = [{
	path: '/receive',
	name: 'Receive',
	component: Receive,
}, {
	path: '/receive/local',
	name: 'ReceiveLocal',
	component: ReceiveLocal,
}, {
	path: '/receive/house',
	name: 'ReceiveHouse',
	component: ReceiveHouse,
}, {
	path: '/receive/prompt/:state',
	name: 'ReceivePrompt',
	component: ReceivePrompt,
}, {
	path: '/receive/success',
	name: 'ReceiveSuccess',
	component: ReceiveSuccess,
}]

export default receive
