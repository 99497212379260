import Tenant from '@/views/tenant/Tenant.vue'
import TenantDetail from '@/views/tenant/Detail.vue'
import TenantRenew from '@/views/tenant/Renew.vue'
import TenantEdit from '@/views/tenant/Edit.vue'

const tenant = [{
	path: '/tenant',
	name: 'Tenant',
	component: Tenant,
}, {
	path: '/tenant/detail/:id/:tab?',
	name: 'TenantDetail',
	component: TenantDetail,
}, {
	path: '/tenant/renew/:id',
	name: 'TenantRenew',
	component: TenantRenew,
}, {
	path: '/tenant/edit/:id',
	name: 'TenantEdit',
	component: TenantEdit,
}]

export default tenant
