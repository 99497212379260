<template>
	<div class="detail container">
		<van-nav-bar title="租客详情" left-arrow @click-left="back()" fixed />
		<van-tabs v-model="tabActive" color="#1772D1">
			<van-tab title="租房详情" :badge="(tenant.status == 1 || tenant.status == 3) && tenant.is_expired == 0 ? 1 : ''">
				<div class="card">
					<div class="card-head van-hairline--bottom">
						<h3>租房详情</h3>
					</div>
					<div class="card-body">
						<div class="cell van-hairline--bottom" v-if="tenant.state != 4">
							<div class="cell-title">租客风险值</div>
							<div class="cell-value">
								<van-tag v-if="tenant.state == 1" type="success">相对安全</van-tag>
								<van-tag v-if="tenant.state == 2" type="warning">一般关注</van-tag>
								<van-tag v-if="tenant.state == 3" type="danger">重点关注</van-tag>
							</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">姓名</div>
							<div class="cell-value">{{tenant.fullname}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">电话</div>
							<div class="cell-value">{{tenant.tel}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">证件号</div>
							<div class="cell-value">{{tenant.id_number}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">户籍地址</div>
							<div class="cell-value">{{tenant.address}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">承租日期</div>
							<div class="cell-value">{{tenant.start_date}} - {{tenant.end_date}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">工作单位</div>
							<div class="cell-value">{{tenant.work_place}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">房屋照片</div>
							<div class="cell-value">
								<div class="cell-value">
									<img class="cell-image" @click="preview(tenant.house_photo_url)" :src="tenant.house_photo_url" alt="">
								</div>
							</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">现场照片</div>
							<div class="cell-value">
								<div class="cell-value">
									<img class="cell-image" @click="preview(tenant.scene_photo_url)" :src="tenant.scene_photo_url" alt="">
								</div>
							</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">租房合同</div>
							<div class="cell-value">
								<div class="cell-value">
									<img v-for="(item, index) in tenant.renting_contract_url" class="cell-image" @click="preview(tenant.renting_contract_url, index)" :src="item" alt="">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="tenant.is_expired == 0">
					<div class="h-50" v-if="tenant.status == 1 || tenant.status == 3"></div>
					<div class="confirm" v-if="tenant.status == 1">
						<van-button type="info" block @click="confirm">确认无误</van-button>
						<van-button type="danger" block @click="refuse">信息不符</van-button>
					</div>
					<div class="confirm" v-if="tenant.status == 3">
						<van-button type="danger" block @click="contract">同意</van-button>
					</div>
				</div>
			</van-tab>
			<van-tab title="随行人员" :badge="entouragePendingTotal > 0 ? this.entouragePendingTotal : ''">
				<div class="card visit" v-for="(item, key) in entourage">
					<div class="card-head van-hairline--bottom">
						<h3>随行人员</h3>
					</div>
					<div class="card-body">
						<div class="cell van-hairline--bottom" v-if="item.state != 4">
							<div class="cell-title">人员风险值</div>
							<div class="cell-value">
								<van-tag v-if="item.state == 1" type="success">相对安全</van-tag>
								<van-tag v-if="item.state == 2" type="warning">一般关注</van-tag>
								<van-tag v-if="item.state == 3" type="danger">重点关注</van-tag>
							</div>
						</div>
						<div class="cell van-hairline--bottom" v-if="tenant.status == 2 && tenant.is_expired == 0">
							<div class="cell-title">人员状态</div>
							<div class="cell-value">
								<van-tag v-if="item.status == 1" type="danger">待确认</van-tag>
								<van-tag v-if="item.status == 2" type="success">正常</van-tag>
								<van-tag v-if="item.status == 3" type="danger">待房东删除</van-tag>
							</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">姓名</div>
							<div class="cell-value">{{item.fullname}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">电话</div>
							<div class="cell-value">{{item.tel}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">证件号</div>
							<div class="cell-value">{{item.id_number}}</div>
						</div>
						<div class="cell van-hairline--bottom">
							<div class="cell-title">现场照片</div>
							<div class="cell-value">
								<div class="cell-value">
									<img class="cell-image" @click="preview(item.scene_photo_url)" :src="item.scene_photo_url" alt="">
								</div>
							</div>
						</div>
						<div class="cell cell-btn-group" v-if="tenant.status == 2 && tenant.is_expired == 0">
							<template v-if="item.status == 1">
								<van-button size="small" type="primary" color="#46B7FB" @click="confirmEntourage(item, key)">确认</van-button>
								<van-button size="small" type="danger" @click="deleteEntourage(item, key)">删除</van-button>
							</template>

							<template v-if="item.status == 2">
								<van-button size="small" type="danger" @click="deleteEntourage(item, key)">删除</van-button>
							</template>

							<template v-if="item.status == 3">
								<van-button size="small" type="danger" @click="deleteEntourage(item, key)">确认删除</van-button>
								<van-button size="small" type="primary" color="#46B7FB" @click="refuseEntourage(item, key)">拒绝删除</van-button>
							</template>
						</div>
					</div>
				</div>
				<div class="card visit" v-if="entourage.length <= 0">
					<div class="card-head van-hairline--bottom">
						<h3>随行人员</h3>
					</div>
					<div class="card-body">
						<p class="empty-tips">无记录</p>
					</div>
				</div>
			</van-tab>
		</van-tabs>
		<Preloading :loading="preloading" />
	</div>
</template>

<script>
	import Utils from "@/utils/util"
	import Event from "@/store/event"
	import Preloading from "@/components/Preloading"

	export default {
		name: 'HouseTenantDetail',
		data() {
			return {
				tenant: "",
				entourage: [],
				tabActive: 0,
				preloading: true,
				entouragePendingTotal: ""
			}
		},
		created() {
			this.tabActive = parseInt(this.$route.params.tab) || 0

			// 获取房屋详情
			this.$axios.post('/proxy/send', {
				params: {
					url: "/house/tenantDetail"
				},
				data: {
					id: this.$route.params.id
				}
			}).then(result => {
				if (result.code == 2000) {
					this.tenant = result.data.tenant
					this.entourage = result.data.entourage
					this.entouragePendingTotal = result.data.entourage_pending_total || ""
					this.preloading = false
				} else {
					this.$toast(result.msg)
				}
			})
		},
		methods: {
			deleteEntourage(data, key) {
				this.$dialog.confirm({
					title: '提示',
					message: "确认删除此随行人员吗？",
				}).then(() => {
					this.$toast.loading({
						message: "正在提交"
					})
					this.$axios.post('/proxy/send', {
						params: {
							url: "/entourage/delete"
						},
						data: {
							id: data.id,
							type: 1
						}
					}).then(res => {
						if (res.code == 2000) {
							if (this.entourage[key]['status'] == 1 || this.entourage[key]['status'] == 3) {
								this.entouragePendingTotal--
								Event.$emit('house_count', {
									id: this.tenant.house_id
								})

								Event.$emit('tenant_count', {
									id: this.tenant.id
								})
							}
							this.entourage.splice(key, 1)
							this.$toast.success("删除成功！")
						} else {
							this.$toast.fail(res.msg);
						}
					});
				});
			},
			confirmEntourage(data, key) {
				this.$dialog.confirm({
					title: '提示',
					message: "确认无误此随行人员信息吗？",
				}).then(() => {
					this.$toast.loading({
						message: "正在提交"
					})
					this.$axios.post('/proxy/send', {
						params: {
							url: "/entourage/confirm"
						},
						data: {
							id: data.id
						}
					}).then(res => {
						if (res.code == 2000) {
							this.entourage[key]['status'] = 2
							this.entouragePendingTotal--
							Event.$emit('house_count', {
								id: this.tenant.house_id
							})
							Event.$emit('tenant_count', {
								id: this.tenant.id
							})
							this.$toast.success("确认成功！")
						} else {
							this.$toast.fail(res.msg);
						}
					});
				});
			},
			refuseEntourage(data, key) {
				this.$dialog.confirm({
					title: '提示',
					message: "拒绝删除此随行人员的删除请求吗？",
				}).then(() => {
					this.$toast.loading({
						message: "正在提交"
					})
					this.$axios.post('/proxy/send', {
						params: {
							url: "/entourage/refuse"
						},
						data: {
							id: data.id
						}
					}).then(res => {
						if (res.code == 2000) {
							this.entourage[key]['status'] = 2
							this.entouragePendingTotal--
							Event.$emit('house_count', {
								id: this.tenant.house_id
							})
							Event.$emit('tenant_count', {
								id: this.tenant.id
							})
							this.$toast.success("拒绝成功！")
						} else {
							this.$toast.fail(res.msg);
						}
					});
				});
			},
			// 确认租户信息
			confirm() {
				this.$dialog.confirm({
					title: "提示",
					message: "请确认租户信息无误后点击确认",
				}).then(() => {
					this.$toast.loading({
						message: "正在提交"
					})
					this.$axios.post('/proxy/send', {
						params: {
							url: "/tenant/confirmInfo"
						},
						data: {
							id: this.$route.params.id
						}
					}).then(res => {
						if (res.code == 2000) {
							this.tenant.status = 2
							this.entouragePendingTotal = ""
							this.entourage.forEach((item, key) => {
								item.status = 2
							})
							Event.$emit('tenant_update', {
								id: this.tenant.id,
								status: this.tenant.status
							})
							Event.$emit('house_count', {
								id: this.tenant.house_id
							})
							this.$toast.success("确认成功！");
						} else {
							this.$toast(res.msg);
						}
					});
				});
			},
			// 拒绝租客信息
			refuse() {
				this.$dialog.confirm({
					title: "提示",
					message: "确认信息不符吗？",
				}).then(() => {
					this.$toast.loading({
						message: "正在提交"
					})
					this.$axios.post('/proxy/send', {
						params: {
							url: "/tenant/refuse"
						},
						data: {
							id: this.$route.params.id
						}
					}).then(res => {
						if (res.code == 2000) {
							Event.$emit('tenant_delete', {
								id: this.tenant.id
							})
							Event.$emit('house_count', {
								id: this.tenant.house_id
							})
							this.$toast.success("提交成功");
							this.$router.go(-1);
						} else {
							this.$toast(res.msg);
						}
					});
				});
			},
			// 解约确认
			contract() {
				this.$dialog.confirm({
					title: "提示",
					message: "确认解约吗？",
				}).then(() => {
					this.$toast.loading({
						message: "正在提交"
					})
					this.$axios.post('/proxy/send', {
						params: {
							url: "/tenant/confirmRelieve"
						},
						data: {
							id: this.$route.params.id
						}
					}).then(res => {
						if (res.code == 2000) {
							this.tenant.status = 4;
							Event.$emit('tenant_update', {
								id: this.tenant.id,
								status: this.tenant.status
							})
							Event.$emit('house_count', {
								id: this.tenant.house_id
							})
							this.$toast.success("解约成功")
						} else {
							this.$toast(res.msg);
						}
					});
				});
			},
			preview(images, index) {
				Utils.preview(images, index)
			}
		},
		components: {
			Preloading
		}
	}
</script>

<style lang="less" scoped>
	.detail {
		background-color: #F2F2F2;
		padding-top: 50px;

		.card-body {
			padding: 0 10px 10px;
		}
		
		span.van-tag{
			font-size: 16px;
			line-height: 1.2;
		}

		.confirm {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			display: flex;

			button {
				flex: 1;
				border-radius: 0;
				font-size: 18px;
			}
		}
	}
</style>
