import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);


export default new Vuex.Store({
	state: {
		keepAlive: [],
		data: ""
	},
	mutations: {
		setKeepAlive(state, val) {
			state.keepAlive.push(val);
		},
		removeKeepAlive(state, val) {
			state.keepAlive.splice(state.keepAlive.findIndex(e => e === val), 1)
		},
		clearKeepAlive(state) {
			state.keepAlive = [];
		},
		setData(state, data) {
			state.data = data;
		},
		clearData(state) {
			state.data = "";
		}
	}
})
