<template>
	<div class="miss container">
		<van-nav-bar title="页面不存在" left-arrow @click-left="back()" fixed />
		<div class="miss-center">
			<img class="miss-img" src="@/assets/img/miss.png" alt="">
			<p class="miss-tips">页面不存在</p>
			<div class="miss-btns">
				<van-button type="info" @click="$router.replace({name: 'Index'})" block>返回首页</van-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Miss"
	}
</script>

<style lang="less" scoped="">
	.miss {
		padding-top: 50px;
		position: relative;

		.miss-center {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			width: 100%;
			padding: 15px;
			box-sizing: border-box;

			.miss-img {
				display: block;
				margin: 0 auto;
				width: 180px;
			}

			.miss-tips {
				text-align: center;
				font-size: 20px;
				color: #666;
			}

			.miss-btns {
				padding-top: 100px;

				button {
					margin-bottom: 15px;
				}

				.van-button--info {
					background-color: #1772D1;
					border: 1px solid #1772D1;
				}

				.van-button--plain {
					background-color: #fff;
					
					&.van-button--info{
						color: #1772D1;
					}
				}
			}
		}
	}
</style>
