<template>
	<div class="house container">
		<van-nav-bar title="居住证申领登记" left-arrow @click-left="back()" fixed />
		<Question :title="title" :button="button" />
	</div>
</template>

<script>
	import Question from "@/components/Question.vue"

	export default {
		name: "ReceiveHouse",
		data() {
			return {
				title: "您在信州区是否有房产？",
				button: [{
					text: "有",
					click: () => {
						this.$router.push({
							name: "ReceivePrompt",
							params: {
								state: 2
							}
						});
					}
				}, {
					text: "无",
					click: () => {
						this.$router.push({
							name: 'Receive'
						});
					}
				}]
			}
		},
		components: {
			Question
		}
	}
</script>

<style lang="less" scoped>
	.house {
		padding-top: 49px;
		background-color: #F2F2F2;
	}
</style>
