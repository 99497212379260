import Info from '@/views/info/Info.vue'
import Personal from '@/views/personal/Personal.vue'
import InfoSuccess from '@/views/info/Success.vue'

const personal = [{
	path: '/personal',
	name: 'Personal',
	component: Personal,
}, {
	path: '/info',
	name: 'Info',
	component: Info,
}, {
	path: '/info/success',
	name: 'InfoSuccess',
	component: InfoSuccess,
}]

export default personal
