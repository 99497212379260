<template>
	<div class="receive container">
		<van-nav-bar title="居住证申领" left-arrow @click-left="back()" fixed />
		<van-form class="form" @submit="onSubmit">
			<div class="card">
				<div class="card-head van-hairline--bottom">
					<h3>房屋信息</h3>
				</div>
				<div class="card-body">
					<div class="form">
						<van-field required v-model="show.houseAddress" is-link readonly label="租房地址" placeholder="请选择租房地址" @click="show.selectHouseShow = true" />
						<van-popup v-model="show.selectHouseShow" position="bottom">
							<van-picker title="请选择租房地址" show-toolbar :columns="show.houseOptions" @confirm="selectHouse" @cancel="show.selectHouseShow = false" />
						</van-popup>
						<van-field disabled v-model="show.areaText" label="所属区域" placeholder="请选择" />
						<van-field disabled v-model="show.landlordName" label="房东姓名" placeholder="请选择" />
						<van-field disabled v-model="show.landlordTel" label="房东电话" placeholder="请选择" />
					</div>
				</div>
			</div>
			<div class="card">
				<div class="card-head van-hairline--bottom">
					<h3>申领信息</h3>
				</div>
				<div class="card-body">
					<van-field disabled v-model="data.fullname" label="姓名" placeholder="请输入您的姓名" />
					<van-field disabled v-model="data.sex == 1 ? '男' : '女' " label="性别" placeholder="请输入您的性别" />
					<van-field disabled v-model="data.birth_date" label="出生日期" placeholder="请输入您的出生日期" />
					<van-field disabled v-model="data.id_number" label="身份证" placeholder="请输入身份证" />
					<van-field disabled v-model="data.nation" label="民族" placeholder="请输入您的民族" />
					<van-field required v-model="data.tel" label="联系电话" placeholder="请输入联系电话" />
					<van-field required v-model="data.arrival_date" readonly label="到达日期" placeholder="请选择到达日期" @click="show.selectArrivalDateShow = true" />
					<van-popup v-model="show.selectArrivalDateShow" position="bottom">
						<van-datetime-picker v-model="show.arrivalDate" :min-date="show.minDate" :max-date="show.maxDate" type="date" title="请选择到达日期" @confirm="selectArrivalDate" />
					</van-popup>
					<van-field required v-model="data.live_cause" rows="3" label="居住事由" type="textarea" maxlength="50" placeholder="请输入居住事由" show-word-limit />
				</div>
			</div>
			<div class="submit">
				<van-button block type="info" native-type="submit">保存</van-button>
			</div>
		</van-form>
	</div>
</template>

<script>
	import Utils from "@/utils/util"
	import Storage from '@/storage/storage'

	let currentDate = new Date();
	let minDate = new Date(currentDate.getFullYear() - 70, currentDate.getMonth(), currentDate.getDate());
	let maxDate = currentDate;

	export default {
		data() {
			return {
				show: {
					areaText: "",
					selectHouseShow: false,
					selectArrivalDateShow: false,
					houseAddress: '',
					houseOptions: [],
					landlordName: "",
					landlordTel: "",
					minDate: minDate,
					maxDate: maxDate,
					arrivalDate: currentDate,
				},
				data: {
					hid: "",
					tel: "",
					sex: '1',
					fullname: "",
					id_number: "",
					live_cause: "",
					nation: "",
					birth_date: "",
					arrival_date: "",
				}
			};
		},
		created() {
			let user = Storage.local.get('USER') || "";
			this.data.birth_date = user.birth_date;
			this.data.fullname = user.fullname;
			this.data.tel = user.tel;
			this.data.id_number = user.id_number;
			this.data.sex = user.sex;
			this.data.nation = user.nation;
			this.$axios.post("/proxy/send", {
				params: {
					url: "/residence_permit/getHouseList"
				}
			}).then((res) => {
				if (res.code == 2000) {
					if (Utils.isEmpty(res.data)) {
						return this.$toast.fail("未查询到有效租房信息");
					}
					let data = [];
					for (let i = 0; i < res.data.length; i++) {
						let temp = {
							hid: res.data[i].hid,
							text: res.data[i].house_address + Utils.detailAddress(res.data[i])
						}
						data.push(temp);
					}
					this.show.houseOptions = data;
				} else {
					this.$toast.fail(res.msg);
				}
			});
		},
		methods: {
			selectArrivalDate(val) {
				this.data.arrival_date = Utils.formatDate(val);
				this.show.selectArrivalDateShow = false;
			},
			selectHouse(val) {
				if (Utils.isEmpty(val)) {
					this.show.selectHouseShow = false;
					return this.$toast.fail("未查询到有效租房信息");
				}
				this.data.hid = val.hid;
				this.show.houseAddress = val.text;
				this.show.selectHouseShow = false;
				// 获取房东信息
				this.landlord();
			},
			// 获取房东信息
			landlord(fn) {
				this.$axios.post("/proxy/send", {
					params: {
						url: "/residence_permit/landlordInfo"
					},
					data: {
						hid: this.data.hid
					}
				}).then((res) => {
					if (res.code == 2000) {
						this.show.landlordName = res.data.landlord.fullname;
						this.show.landlordTel = res.data.landlord.tel;
						this.show.areaText = res.data.area_text;
					} else {
						this.$toast.fail("房屋信息获取失败");
					}
				});
			},
			onSubmit() {
				if (Utils.isEmpty(this.data.hid)) {
					return this.$toast("请选择租房地址");
				}
				if (Utils.isEmpty(this.data.tel)) {
					return this.$toast("请输入联系电话");
				}
				if (!Utils.isMobile(this.data.tel)) {
					return this.$toast("请输入正确的11位手机号码");
				}
				if (Utils.isEmpty(this.data.arrival_date)) {
					return this.$toast("请选择到达日期");
				}
				if (Utils.isEmpty(this.data.live_cause)) {
					return this.$toast("请输入居住事由");
				}
				this.$toast.loading('加载中...');
				let data = {
					tel: this.data.tel,
					hid: this.data.hid,
					nation: this.data.nation,
					arrival_date: this.data.arrival_date,
					live_cause: this.data.live_cause,
					sex: this.data.sex,
					birth_date: this.data.birth_date,
				}
				this.$axios.post("/proxy/send", {
					params: {
						url: "/residence_permit/create"
					},
					data: data
				}).then(res => {
					if (res.code == 2000) {
						this.$toast.clear();
						this.$router.push({
							name: "ReceiveSuccess"
						});
					} else {
						this.$toast.fail(res.msg);
					}
				});
			}
		}
	}
</script>

<style lang="less" scoped>
	@import "@/assets/less/form.less";

	.receive {
		background-color: #F2F2F2;
		padding-top: 54px;
		box-sizing: border-box;
	}
</style>
