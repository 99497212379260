<template>
	<div class="declare container" ref="scroll">
		<van-nav-bar title="出租房屋申报" left-arrow @click-left="back()" fixed />
		<div class="tabbar" v-if="manager.manager == 'yes'">
			<div :class="['tabbar-item', data.type == 1 ? 'active' : '']" @click="changeType(1)">
				本人房屋
			</div>
			<div :class="['tabbar-item', data.type == 2 ? 'active' : '']" @click="changeType(2)">
				代录房屋
			</div>
		</div>
		<div class="card" v-if="data.type == 2">
			<div class="card-head van-hairline--bottom">
				<h3>房东信息</h3>
			</div>
			<div class="card-body">
				<van-form class="form">
					<div class="upload-idcard">
						<div class="upload-idcard-item">
							<van-uploader v-model="show.id_fp" capture :after-read="afterRead" :upload-icon="require('@/assets/img/img13.png')" :max-count="1" :preview-options="{closeable: true}" @delete="deleteIdCard" />
							<p>身份证正面</p>
						</div>
						<div class="upload-idcard-item upload-example">
							<img src="@/assets/img/img14.png" alt="">
							<p>示例图片</p>
						</div>
					</div>
					<div class="upload-tips">
						请将您的证件对齐取景框四周，拍照后会自动识别证件信息
					</div>
					<van-field required :disabled="show.allowInput" v-model="user.fullname" name="fullname" label="姓名" placeholder="请输入您的姓名" />
					<van-field required :disabled="show.allowInput" v-model="user.id_number" name="id_number" label="身份证号" placeholder="请输入您的身份证号" />
					<van-field required :disabled="show.allowInput" v-model="user.nation" label="民族" placeholder="请输入您的民族" />
					<van-field label="性别" :disabled="show.allowInput">
						<template #input>
							<van-radio-group v-model="user.sex" direction="horizontal" :disabled="show.allowInput">
								<van-radio name="1">男</van-radio>
								<van-radio name="2">女</van-radio>
							</van-radio-group>
						</template>
					</van-field>
					<van-field required :disabled="show.allowInput" readonly clickable @click="show.showSelectBirthDate = true" v-model="user.birth_date" label="出生日期" placeholder="请选择您的出生日期" />
					<van-popup v-model="show.showSelectBirthDate" position="bottom">
						<van-datetime-picker v-model="show.birthDate" title="出生日期" type="date" show-toolbar @confirm="selectBirthDate" @cancel="show.showSelectBirthDate = false" :min-date="show.birthDateMinDate" :max-date="show.birthDateMaxDate" />
					</van-popup>
					<van-field required :disabled="show.allowInput" v-model="user.address" name="address" label="户籍地址" placeholder="请输入您的户籍地址" />
					<van-field required :disabled="show.allowInput" v-model="user.tel" name="tel" label="联系电话" placeholder="请输入您的联系电话" />
					<van-field :disabled="show.allowInput" v-model="user.tel_replace" name="tel_replace" label="备用电话" placeholder="请输入您的备用电话" />
				</van-form>
			</div>
		</div>

		<div class="card">
			<div class="card-head van-hairline--bottom">
				<h3>房屋信息</h3>
			</div>
			<div class="card-body">
				<van-form class="form">
					<van-field v-model="show.areaText" is-link readonly required label="所在地区" placeholder="请选择所在地区" @click="show.areaCascaderShow = true" />
					<van-popup v-model="show.areaCascaderShow" round position="bottom">
						<van-cascader v-model="show.areaValue" active-color="#1772D1" title="请选择所在地区" :options="show.areaData" @close="show.areaCascaderShow = false" @finish="areaCascaderFinish" :field-names="{text: 'title', value: 'id', children: 'child'}" />
					</van-popup>
					<div class="radio van-hairline--bottom">
						<p class="radio-title block-required">建筑类型</p>
						<div class="radio-wrap">
							<div class="radio-item radio-item-active">
								<label class="radio-label">
									<input type="radio" value="1" v-model="data.btype"><span>商品房</span>
								</label>
							</div>
							<div class="radio-item">
								<label class="radio-label">
									<input type="radio" value="2" v-model="data.btype"><span>公寓</span>
								</label>
							</div>
							<div class="radio-item">
								<label class="radio-label">
									<input type="radio" value="3" v-model="data.btype"><span>自建房</span>
								</label>
							</div>
							<div class="radio-item">
								<label class="radio-label">
									<input type="radio" value="4" v-model="data.btype"><span>店铺</span>
								</label>
							</div>
						</div>
					</div>
					<van-field v-model="data.address" required label="房屋地址" placeholder="定位地址仅供辅助填写" />
					<van-field v-if="this.data.btype == 3" required v-model="data.detail_address" label="详细地址" placeholder="示例: 新开路66号" />
					<div v-else class="inline-field van-hairline--bottom">
						<div class="inline-field-item van-hairline--surround">
							<input v-model="data.build_num" placeholder="楼栋">
						</div>
						<div class="inline-field-item van-hairline--surround">
							<input v-model="data.unit_num" placeholder="单元">
						</div>
						<div class="inline-field-item van-hairline--surround">
							<input v-model="data.room_num" placeholder="房号">
						</div>
					</div>
					<div class="radio van-hairline--bottom">
						<p class="radio-title block-required">房屋用途</p>
						<div class="radio-wrap">
							<div class="radio-item radio-item-active">
								<label class="radio-label">
									<input type="radio" value="1" v-model="data.purpose"><span>出租</span>
								</label>
							</div>
							<div class="radio-item">
								<label class="radio-label">
									<input type="radio" value="2" v-model="data.purpose"><span>自住</span>
								</label>
							</div>
						</div>
					</div>
					<van-field type="number" v-model="data.water_account" label="水费开户号" placeholder="水费开户号 (选填)" />
					<van-field type="number" v-model="data.electric_account" label="电费开户号" placeholder="电费开户号 (选填)" />
					<van-field type="number" v-model="data.gas_account" label="煤气开户号" placeholder="煤气开户号 (选填)" />
					<div class="upload-idcard van-hairline--bottom">
						<div class="upload-idcard-item">
							<van-uploader v-model="show.house_photo" :after-read="uploadHouse" :upload-icon="require('@/assets/img/img11.png')" :max-count="1" :preview-options="{closeable: true}" />
							<p>房屋照片</p>
						</div>
						<div class="upload-idcard-item">
							<van-uploader v-model="show.scene_photo" capture :after-read="uploadScene" :upload-icon="require('@/assets/img/img10.png')" :max-count="1" :preview-options="{closeable: true}" />
							<p>本人照片</p>
						</div>
					</div> 
					<van-field v-model="data.remark" rows="2" autosize label="备注" type="textarea" maxlength="255" placeholder="请输入备注(选填)" show-word-limit />
					<div class="guarantee-preview" v-show="show.guaranty">
						<p class="guarantee-title">保证书</p>
						<div class="guarantee-image-wrap van-hairline--surround">
							<img class="guarantee-image" :src="show.guaranty" @click="guaranteePreview()" alt="">
						</div>
						<p class="guarantee-reset" @click="resetSignature()">签名有误？重新签名</p>
					</div>
					<div class="submit" id="submit">
						<van-button block type="info" @click="onSubmit">提交</van-button>
					</div>
				</van-form>
			</div>
		</div>

		<van-dialog v-model="show.guaranteeShow" title="信州区房屋出租治安责任保证书" show-cancel-button @confirm="guaranteeConfirm">
			<Guarantee :fullname="show.guaranteeShowName" :id-number="show.guaranteeShowIdNumber" :address="detailAddress" />
		</van-dialog>

		<van-popup v-model="show.signatureShow" round position="bottom" :style="{ height: '80%' }" closeable>
			<div class="signature">
				<div class="signature-head">
					请您在方框内签名
				</div>
				<div class="signature-body">
					<canvas class="canvas"></canvas>
				</div>
				<div class="signature-footer">
					<van-button type="default" size="normal" round @click="signatureClear()">重置</van-button>
					<van-button type="info" size="normal" round @click="signatureSave()">保存</van-button>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import Config from '@/config/config'
	import SignaturePad from 'signature_pad'
	import trimCanvas from 'trim-canvas'
	import Utils from "@/utils/util"
	import Vant from "@/vender/vant"
	import Validator from "we-validator"
	import Compressor from 'compressorjs'
	import Storage from '@/storage/storage'
	import Guarantee from '@/components/Guarantee.vue'

	let currentDate = new Date();
	let minDate = new Date(currentDate.getFullYear() - 70, currentDate.getMonth(), currentDate.getDate());
	let maxDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

	export default {
		data() {
			return {
				detail: {
					address: ""
				},
				signaturePad: null,
				show: {
					id_fp: [],
					areaData: [],
					areaText: "",
					guaranty: "",
					areaValue: "",
					house_photo: [],
					scene_photo: [],
					signatureShow: false,
					guaranteeShow: false,
					areaCascaderShow: false,
					allowInput: true,
					birthDate: currentDate,
					birthDateMinDate: minDate,
					birthDateMaxDate: maxDate,
					showSelectBirthDate: false,
					guaranteeShowName: "",
					guaranteeShowIdNumber: "",
				},
				data: {
					type: 1,
					district_id: "",
					ps_id: "",
					jd_id: "",
					jdb_id: "",
					sc_id: "",
					btype: 1,
					longitude: "",
					latitude: "",
					address: "",
					build_num: "",
					unit_num: "",
					room_num: "",
					detail_address: "",
					purpose: 1,
					house_photo: "",
					scene_photo: "",
					guaranty: "",
					water_account: "",
					electric_account: "",
					gas_account: "",
					remark: ""
				},
				user: {
					fullname: "",
					id_number: "",
					address: "",
					tel: "",
					tel_replace: "",
					id_fp: "",
					sex: "",
					birth_date: "",
					nation: "",
				},
				manager: ""
			};
		},
		created() {
			this.manager = Storage.local.get("USER")
			// 获取区域数据
			this.$axios.post("/proxy/send", {
				params: {
					url: "/area/tree"
				}
			}).then(res => {
				if (res.code == 2000) {
					this.show.areaData = res.data;
				} else {
					this.$toast.fail(res.msg);
				}
			});

			Utils.wechat(() => {
				wx.getLocation({
					type: 'wgs84',
					success: (res) => {
						// GPS位置转详细位置
						this.$axios.post("/we_chat/location", {
							latitude: res.latitude,
							longitude: res.longitude,
						}).then((res) => {
							if (res.code == 2000) {
								this.data.address = res.data.address;
								this.data.latitude = res.data.location.lat;
								this.data.longitude = res.data.location.lng;
							} else {
								this.$toast.fail(res.msg);
							}
						});
					}
				});
			});

			// 初始化验证器
			this.validatorInit()
		},
		computed: {
			detailAddress() {
				let address = this.data.address
				if (this.data.btype == 3) {
					address += this.data.detail_address
				} else {
					address += this.data.build_num + "栋"
					address += this.data.unit_num + "单元"
					address += this.data.room_num + "号"
				}
				return address
			}
		},
		watch: {
			"data.btype"(newVal) {
				if (newVal == 3) {
					this.validator.addRules({
						rules: {
							detail_address: {
								required: true
							}
						},
						messages: {
							detail_address: {
								required: "请输入详细地址"
							}
						}
					})
					this.validator.removeRules(["build_num", "unit_num", "room_num"])
				} else {
					this.validator.addRules({
						rules: {
							build_num: {
								required: true,
							},
							unit_num: {
								required: true,
							},
							room_num: {
								required: true
							}
						},
						messages: {
							build_num: {
								required: "请输入楼栋",
							},
							unit_num: {
								required: "请输入单元",
							},
							room_num: {
								required: "请输入房号"
							},
						}
					})
					this.validator.removeRules(['detail_address'])
				}
			},
			"data.type"(newVal) {
				if (newVal == 2) {
					this.validator.addRules({
						rules: {
							id_fp: {
								required: true
							},
							fullname: {
								required: true
							},
							id_number: {
								required: true,
								idcard: true
							},
							nation: {
								required: true
							},
							sex: {
								required: true
							},
							birth_date: {
								required: true
							},
							tel: {
								required: true,
								mobile: true,
							},
							tel_replace: {
								mobile: true,
							},
						},
						messages: {
							id_fp: {
								required: "请上传身份证正面"
							},
							fullname: {
								required: "请输入姓名"
							},
							id_number: {
								required: "请输入身份证号",
								idcard: "身份证号格式错误"
							},
							nation: {
								required: "请输入民族"
							},
							sex: {
								required: "请选择性别"
							},
							birth_date: {
								required: "请选择出生日期"
							},
							tel: {
								required: "请输入电话号码",
								mobile: "请输入正确的11位电话号码",
							},
							tel_replace: {
								mobile: "请输入正确的11位备用电话",
							},
						}
					})
				} else {
					this.validator.removeRules(["id_fp", "fullname", "id_number", "nation", "sex", "birth_date", "tel", "tel_replace"])
				}
			}
		},
		methods: {
			changeType(type) {
				this.data.type = type
			},
			areaCascaderFinish(result) {
				let options = result.selectedOptions
				this.show.areaText = options.map((area) => area.title).join('/');

				this.data.district_id = typeof options[0] == 'undefined' ? 0 : options[0].id
				this.data.jd_id = typeof options[1] == 'undefined' ? 0 : options[1].id
				this.data.jdb_id = typeof options[2] == 'undefined' ? 0 : options[2].id
				this.data.sc_id = typeof options[3] == 'undefined' ? 0 : options[3].id

				this.show.areaCascaderShow = false;
			},
			onSubmit() {

				let data = {
					...this.user,
					...this.data
				}

				data.id_fp = this.getUploadImage(this.show.id_fp)
				data.house_photo = this.getUploadImage(this.show.house_photo)
				data.scene_photo = this.getUploadImage(this.show.scene_photo)

				let result = this.validator.checkData(data, result => {
					this.$toast(result.msg);
				})

				if (false == result) {
					return false
				}

				// 签保证书
				if (!Validator.checkValue('required', data.guaranty)) {
					if (this.data.type == 1) {
						this.show.guaranteeShowName = this.manager.fullname
						this.show.guaranteeShowIdNumber = this.manager.id_number
					} else {
						this.show.guaranteeShowName = this.user.fullname
						this.show.guaranteeShowIdNumber = this.user.id_number
					}
					return this.show.guaranteeShow = true;
				}

				// 自建房房号则为详细地址
				if (data.btype == 3) {
					data.build_num = ""
					data.unit_num = ""
					data.room_num = this.data.detail_address
				} else {
					data.build_num = this.data.build_num
					data.unit_num = this.data.unit_num
					data.room_num = this.data.room_num
				}

				// 如果没有允许获取GPS坐标则设置默认位置
				data.latitude = data.latitude || 28.430483;
				data.longitude = data.longitude || 117.965916;

				this.$toast.loading({
					message: '正在提交...'
				});
				this.$axios.post("/proxy/send", {
					params: {
						url: "/house/create"
					},
					data: data
				}).then((res) => {
					if (res.code == 2000) {
						this.$toast.clear();
						this.$router.replace({
							name: 'DeclareSuccess',
							params: {
								id: res.data.id
							}
						});
					} else {
						this.$toast.fail(res.msg);
					}
				});
			},
			// 房屋照片上传
			uploadHouse(file) {
				Utils.upload(file, 'house_photo')
			},
			getUploadImage(data) {
				let result = [];
				for (let i = 0; i < data.length; i++) {
					if (!Utils.isEmpty(data[i].relpath)) {
						result.push(data[i].relpath)
					}
				}
				return result.length == 1 ? result[0] : result
			},
			// 场景照片上传
			uploadScene(file) {
				Utils.upload(file, 'scene_photo')
			},
			// 确认保证书内容
			guaranteeConfirm() {
				this.show.signatureShow = true;
				if (this.signaturePad) {
					return false;
				}
				setTimeout(() => {
					let canvas = this.$el.querySelector(".canvas");
					let signatureBody = this.$el.querySelector(".signature-body");
					canvas.width = signatureBody.clientWidth;
					canvas.height = signatureBody.clientHeight;
					this.signaturePad = new SignaturePad(canvas);
				}, 300);
			},
			// 保存签名
			signatureSave() {
				if (this.signaturePad.isEmpty()) {
					return this.$toast("签名不能为空")
				}
				this.$toast.loading({
					message: "生成中"
				})
				// 去除签名周围空白
				let copy = document.createElement('canvas');
				let signaturePad = this.$el.querySelector(".canvas");
				// 重新设置签名比例
				copy.width = signaturePad.width;
				copy.height = signaturePad.height;
				// 划入签名
				copy.getContext('2d').drawImage(signaturePad, 0, 0, copy.width, copy.height);
				// 去除签名边上空白
				trimCanvas(copy);

				let data = {
					base64: copy.toDataURL("image/png")
				}

				// 房屋完整地址
				data.address = this.data.address;
				if (this.data.btype == 3) {
					data.address += this.data.detail_address
				} else {
					data.address += this.data.build_num + "栋"
					data.address += this.data.unit_num + "单元"
					data.address += this.data.room_num + "号"
				}

				// 带录入和本人
				if (this.data.type == 1) {
					data.fullname = this.manager.fullname
					data.id_number = this.manager.id_number
				} else {
					data.fullname = this.user.fullname
					data.id_number = this.user.id_number
				}

				this.$axios.post("/proxy/send", {
					params: {
						url: "/guaranty/create"
					},
					data: data
				}).then((res) => {
					if (res.code == 2000) {
						this.data.guaranty = res.data.relpath;
						this.show.guaranty = res.data.url;
						setTimeout(() => {
							this.$toast.clear();
							window.scrollTo({
								'top': document.querySelector('#submit').offsetTop - 800,
								'behavior': 'smooth'
							})
						}, 300);
					} else {
						this.$toast.fail('保证书生成失败');
					}

				});
				this.show.signatureShow = false;
			},
			// 重新签名
			resetSignature() {
				this.show.signatureShow = true;
			},
			// 清空签名
			signatureClear() {
				this.signaturePad.clear();
			},
			// 保证书预览
			guaranteePreview() {
				if (Utils.isEmpty(this.show.guaranty)) {
					return this.$toast("暂无图片可预览");
				}
				Vant.ImagePreview({
					images: [this.show.guaranty],
					closeable: true,
				});
			},
			afterRead(file) {
				file.status = 'uploading';
				file.message = '识别中...';
				new Compressor(file.file, {
					maxWidth: 800,
					quality: 0.8,
					success: (result) => {
						let data = new FormData();
						data.append('idCard', result, result.name);
						this.$axios.post("/upload/idCard", data).then((res) => {
							if (res.code == 2000) {
								file.status = 'done';
								file.message = '识别成功';
								file.content = res.data.url;
								file.relpath = res.data.relpath;

								let data = res.data;
								this.user.id_fp = res.data.relpath;
								this.user.fullname = data.name;
								this.user.id_number = data.id_number;
								this.user.nation = data.nation;
								this.user.sex = data.sex.toString();
								this.user.address = data.address;
								this.user.birth_date = data.birth_date;
								this.show.birthDate = new Date(data.birth_date);
								this.show.allowInput = false;
							} else {
								file.status = 'failed';
								file.message = res.msg;
							}
						});
					},
					error() {
						this.$toast.fail("证件图片压缩失败！");
					}
				});
			},
			selectBirthDate(val) {
				this.data.birth_date = Utils.formatDate(val);
				this.show.showSelectBirthDate = false;
			},
			deleteIdCard() {
				this.show.allowInput = true;
			},
			validatorInit() {
				// 初始化验证器
				this.validator = new Validator({
					rules: {
						district_id: {
							required: true
						},
						address: {
							required: true
						},
						build_num: {
							required: true,
						},
						unit_num: {
							required: true,
						},
						room_num: {
							required: true
						},
						house_photo: {
							required: true
						},
						scene_photo: {
							required: true
						},
						water_account: {
							length: 10,
							digits: true
						},
						electric_account: {
							length: 10,
							digits: true
						},
						gas_account: {
							length: 10,
							digits: true
						},
					},
					messages: {
						district_id: {
							required: "请选择所属地区"
						},
						address: {
							required: "请输入房屋地址"
						},
						build_num: {
							required: "请输入楼栋",
							pattern: "楼栋必须为数字或字母"
						},
						unit_num: {
							required: "请输入单元",
							pattern: "单元必须为数字或字母"
						},
						room_num: {
							required: "请输入房号"
						},
						house_photo: {
							required: "请上传房屋照片"
						},
						scene_photo: {
							required: "请上传本人照片"
						},
						water_account: {
							length: "水费开户号不正确",
							digits: "水费开户号必须为数字"
						},
						electric_account: {
							length: "电费开户号不正确",
							digits: "电费开户号必须为数字"
						},
						gas_account: {
							length: "煤气开户号不正确",
							digits: "煤气开户号必须为数字"
						},
					}
				})
			}
		},
		components: {
			Guarantee
		}
	}
</script>

<style lang="less" scoped>
	@import "./../../assets/less/form.less";

	.declare {
		background-color: #F2F2F2;
		padding-top: 50px;

		.upload-example {
			img {
				width: 135px;
			}
		}

		.upload-tips {
			margin: 0 10px;
			padding: 10px;
			background-color: #fffbe8;
			font-size: 14px;
			color: #ed6a0c;
		}

		.upload-idcard {
			padding-bottom: 5px;
		}

		.signature {
			position: relative;
			height: 100%;
			box-sizing: border-box;
			width: 100%;
			padding: 15px;

			.signature-head {
				text-align: center;
				color: #999;
			}

			.signature-body {
				border: 1px solid #eee;
				margin: 15px 0;
				height: calc(100% - 95px);
			}

			.signature-footer {
				width: 100%;
				position: absolute;
				bottom: 15px;
				left: 0;
				display: flex;

				.van-button {
					flex: 1;
					margin: 0 10px;
				}
			}
		}

		.guarantee-preview {
			padding: 0 15px 15px;
			box-sizing: border-box;

			.guarantee-title {
				font-size: 16px;
				line-height: 32px;
				margin: 8px 0 5px;
				color: #666;
			}


			.guarantee-image-wrap {
				padding: 1px;

				.guarantee-image {
					width: 100%;
					object-fit: cover;
					display: block;
				}
			}

			.guarantee-reset {
				text-align: right;
				font-size: 14px;
				color: rgba(23, 114, 209, 0.6);
				margin: 5px 0 0;
			}
		}
	}
</style>
