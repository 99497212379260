import { render, staticRenderFns } from "./Tenant.vue?vue&type=template&id=319ace8b&scoped=true&"
import script from "./Tenant.vue?vue&type=script&lang=js&"
export * from "./Tenant.vue?vue&type=script&lang=js&"
import style0 from "./Tenant.vue?vue&type=style&index=0&id=319ace8b&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "319ace8b",
  null
  
)

export default component.exports