<template>
	<div class="container personal" ref="scroll">
		<van-nav-bar title="个人中心" left-arrow @click-left="back()" />
		<div class="head">
			<img class="avatar" :src="headimgurl" alt="">
			<div class="personal-info">
				<p class="nickname">{{nickname}}</p>
				<p class="tel" v-if="tel">手机号：{{tel}}</p>
				<p class="tel" v-else>资料待完善</p>
			</div>
		</div>
		<div class="personal-menu">
			<van-cell-group>
				<van-cell title="我的资料" icon="contact" is-link :to="{name: 'Info'}" />
				<van-cell icon="wap-home-o" is-link :to="{name: 'House', params: {type: 1}}">
					<template #title>
						<span class="custom-title">我的房屋</span>
						<van-tag type="danger" v-if="houseTotal > 0">{{houseTotal}}</van-tag>
					</template>
				</van-cell>
				<van-cell :icon="require('@/assets/img/img17.png')" is-link :to="{name: 'Tenant'}">
					<template #title>
						<span class="custom-title">我的租房</span>
					</template>
				</van-cell>
				<van-cell is-link icon="hotel-o" :to="{name: 'House', params: {type: 2}}" v-if="manager">
					<template #title>
						<span class="custom-title">代录入房屋</span>
						<van-tag type="danger" v-if="insteadTotal > 0">{{insteadTotal}}</van-tag>
					</template>
				</van-cell>
				<van-cell is-link title="我的居住证" :icon="require('@/assets/img/img18.png')" :to="{name: 'ResidencePermit'}" />
				<van-cell is-link title="民警监督" :icon="require('@/assets/img/img16.png')" :to="{name: 'Supervise'}" v-if="manager" />
			</van-cell-group>
		</div>
		<div class="personal-menu">
			<van-cell-group>
				<van-cell title="帮助" icon="question-o" is-link :to="{name: 'Help'}" />
			</van-cell-group>
		</div>
		<div class="logout">
			<van-button block class="logout-btn" type="danger" @click="logout">退出登录</van-button>
		</div>
	</div>
</template>

<script>
	import Storage from '@/storage/storage'

	export default {
		created() {
			let user = Storage.local.get('USER') || {};

			this.tel = user.tel;
			this.nickname = user.nickname;
			this.manager = user.manager == "yes" ? true : false;
			this.headimgurl = user.headimgurl;

			this.$axios.post("/proxy/send", {
				params: {
					url: "/user/total"
				}
			}).then((result) => {
				if (result.code == 2000) {
					this.houseTotal = result.data.house_total
					this.insteadTotal = result.data.instead_total
				}
			});
		},
		data() {
			return {
				tel: "",
				nickname: "",
				headimgurl: "",
				manager: false,
				houseTotal: 0,
				insteadTotal: 0,
			}
		},
		methods: {
			logout() {
				this.$dialog.confirm({
					message: '确认退出并关闭吗？',
				}).then(() => {
					Storage.local.clear();
					WeixinJSBridge.call('closeWindow');
				});
			}
		}
	}
</script>

<style lang="less" scoped>
	.personal {
		width: 100vw;
		height: 100vh;
		background-color: #F2F2F2;
		overflow: auto;
		
		.logout-btn{
			font-size: 18px;
		}
		
		.head {
			margin: 8px;
			padding: 32px 16px;
			background-color: #fff;
			display: flex;

			.avatar {
				width: 64px;
				height: 64px;
				object-fit: cover;
				border-radius: 8px;
			}

			.personal-info {
				margin-left: 16px;

				.nickname {
					font-size: 24px;
					line-height: 31px;
					margin: 0;
					padding: 4px 0 8px;
				}

				.tel {
					font-size: 19px;
					color: #999;
					line-height: 21px;
					margin: 0;
				}
			}
		}

		.personal-menu {
			padding: 0 8px 8px;

			.van-cell-group::after {
				display: none;
			}

			.van-cell {
				padding: 15px 16px;
				font-size: 19px;
				color: #666;

				.van-tag {
					display: inline-block;
					margin-left: 5px;
					vertical-align: text-top;
					font-size: 12px;
					width: 16px;
					height: 16px;
					padding: 0;
					text-align: center;
					line-height: 16px;
				}

				.van-cell__left-icon {
					color: #1772D1;
					font-size: 24px;
					margin-right: 5px;
				}
			}
		}

		.logout {
			padding: 15px 8px 30px;
			width: 100%;
			box-sizing: border-box;

			.van-button--danger {
				background-color: #FF9495;
				border: 1px solid #FF9495;
			}
		}
	}
</style>
