import Prompt from '@/views/renting/Prompt.vue'
import Renting from '@/views/renting/Renting.vue'
import RentingSuccess from '@/views/renting/Success.vue'

const renting = [{
		path: '/prompt/:token',
		name: 'Prompt',
		component: Prompt,
	},
	{
		path: '/prompt/renting/:token',
		name: 'Renting',
		component: Renting,
	}, {
		path: '/prompt/renting/success',
		name: 'RentingSuccess',
		component: RentingSuccess,
	}
]

export default renting
