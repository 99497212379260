<template>
	<div class="declare container">
		<van-nav-bar title="修改信息" left-arrow @click-left="back()" fixed />
		<van-form class="form" @submit="onSubmit">
			<div class="card">
				<div class="card-head van-hairline--bottom">
					<h3>房客信息</h3>
				</div>
				<div class="card-body">
					<div class="upload-idcard van-hairline--bottom">
						<div class="upload-idcard-item">
							<van-uploader v-model="show.housePhoto" :after-read="uploadHouse" :upload-icon="require('@/assets/img/img11.png')" :max-count="1" :preview-options="{closeable: true}" />
							<p>房屋照片</p>
						</div>
						<div class="upload-idcard-item">
							<van-uploader v-model="show.scenePhoto" capture :after-read="uploadScene" :upload-icon="require('@/assets/img/img10.png')" :max-count="1" :preview-options="{closeable: true}" />
							<p>本人照片</p>
						</div>
					</div>
					<van-field required v-model="data.fullname" label="房客姓名" placeholder="请输入房客姓名" disabled />
					<van-field required v-model="data.id_number" label="身份证号码" placeholder="请输入身份证号码" disabled />
					<van-field required v-model="data.address" label="户籍地址" placeholder="请输入户籍地址" disabled />
					<van-field required v-model="data.tel" label="联系电话" placeholder="请输入联系电话" />
					<van-field required v-model="showDate" label="承租日期" @click="show.startDateShow = true" readonly clickable placeholder="请选择承租日期" />
					<van-field required v-model="data.work_place" label="工作单位" placeholder="请输入工作单位" />
					<van-field required label="随行人员">
						<template #input>
							<van-radio-group v-model="data.entourage" direction="horizontal">
								<van-radio name="1">有</van-radio>
								<van-radio name="2">无</van-radio>
							</van-radio-group>
						</template>
					</van-field>
					<van-field required label="租房合同">
						<template #input>
							<van-uploader :after-read="uploadContract" v-model="show.rentingContract" :preview-options="{closeable: true}" />
						</template>
					</van-field>
				</div>
			</div>

			<div class="card" v-for="(item, key) in entourage" v-show="data.entourage == '1'">
				<div class="card-head van-hairline--bottom">
					<h3>随行人员</h3>
				</div>
				<div class="card-body">
					<van-field required v-model="item.fullname" label="房客姓名" placeholder="请输入房客姓名" />
					<van-field required v-model="item.id_number" label="身份证号码" placeholder="请输入身份证号码" />
					<van-field required v-model="item.tel" label="联系电话" placeholder="请输入联系电话" />
					<van-field required label="本人照片">
						<template #input>
							<van-uploader :after-read="uploadScene" v-model="item.scene_photo" :preview-options="{closeable: true}" :max-count="1" />
						</template>
					</van-field>
				</div>
				<div class="add">
					<div class="add-flex van-hairline--top">
						<div class="add-item add-create" @click="createPerson">
							<van-icon name="add-o" />添加
						</div>
						<div class="add-item add-delete" v-show="entourage.length > 1" @click="deletePerson(key)">
							<van-icon name="close" />删除
						</div>
					</div>
				</div>
			</div>
			<div class="submit">
				<van-button block type="info" native-type="submit">提交</van-button>
			</div>
		</van-form>

		<van-popup v-model="show.startDateShow" position="bottom">
			<van-datetime-picker v-model="show.currentStartDate" type="date" title="承租开始日期" :min-date="show.startMinDate" :max-date="show.startMaxDate" @confirm="confirmStartDate" @cancel="clearDate" />
		</van-popup>
		<van-popup v-model="show.endDateShow" position="bottom">
			<van-datetime-picker v-model="show.currentEndDate" type="date" title="承租结束日期" :min-date="show.startDate" :max-date="show.startMaxDate" @confirm="confirmEndDate" @cancel="clearDate" />
		</van-popup>
		<Preloading :loading="show.preloading" />
	</div>
</template>

<script>
	import Utils from "@/utils/util"
	import Validator from "we-validator"
	import Storage from "@/storage/storage"
	import Preloading from "@/components/Preloading"

	let currentDate = new Date();
	let minDate = new Date(currentDate.getFullYear() - 10, currentDate.getMonth() + 1, currentDate.getDate());
	let maxDate = new Date(currentDate.getFullYear() + 10, currentDate.getMonth() + 1, currentDate.getDate());

	export default {
		data() {
			return {
				show: {
					preloading: true,
					housePhoto: [],
					scenePhoto: [],
					currentStartDate: currentDate,
					currentEndDate: currentDate,
					startMinDate: minDate,
					startMaxDate: maxDate,
					endMinDate: minDate,
					endMaxDate: maxDate,
					startDateShow: false,
					endDateShow: false,
					rentingContract: [],
				},
				data: {
					id: this.$route.params.id,
					id_number: '',
					fullname: '',
					tel: '',
					address: '',
					house_photo: '',
					scene_photo: '',
					start_date: '',
					end_date: '',
					work_place: '',
					renting_contract: "",
					entourage: '2' // 1 无  2 有
				},
				entourage: []
			};
		},
		created() {
			let user = Storage.local.get("USER");

			this.data.tel = user.tel;
			this.data.address = user.address;
			this.data.fullname = user.fullname;
			this.data.id_number = user.id_number;

			this.validatorInit()
			// this.createPerson()

			this.$axios.post("/proxy/send", {
				params: {
					url: "/tenant/detail"
				},
				data: {
					id: this.$route.params.id
				}
			}).then((result) => {
				if (result.code == 2000) {
					let tenant = result.data.tenant
					let entourage = result.data.entourage

					this.data.id = tenant.id
					this.data.tel = tenant.tel
					this.data.end_date = Utils.formatDate(new Date(tenant.end_date), "/");
					this.data.start_date = Utils.formatDate(new Date(tenant.end_date), "/");
					this.data.work_place = tenant.work_place

					// 显示数据
					this.show.currentStartDate = new Date(tenant.start_date)
					this.show.currentEndDate = new Date(tenant.end_date)

					// 房屋照片
					this.show.housePhoto = [{
						url: tenant.house_photo_url,
						relpath: tenant.house_photo
					}]

					// 本人照片
					this.show.scenePhoto = [{
						url: tenant.scene_photo_url,
						relpath: tenant.scene_photo
					}]

					// 租房合同照片
					for (let i = 0; i < tenant.renting_contract_url.length; i++) {
						this.show.rentingContract.push({
							url: tenant.renting_contract_url[i],
							relpath: tenant.renting_contract[i]
						})
					}

					// 随行人员
					if (entourage.length > 0) {
						this.data.entourage = '1'

						for (let vo in entourage) {
							this.entourage.push({
								fullname: entourage[vo].fullname,
								id_number: entourage[vo].id_number,
								tel: entourage[vo].tel,
								scene_photo: [{
									url: entourage[vo].scene_photo_url,
									relpath: entourage[vo].scene_photo,
								}]
							})
						}
					}

					this.show.preloading = false;
				} else {
					this.$toast.fail(result.msg);
				}
			});
		},
		computed: {
			showDate() {
				if (this.data.end_date && this.data.start_date) {
					return this.show.showDate = this.data.start_date + ' - ' + this.data.end_date;
				}
			}
		},
		methods: {
			createPerson() {
				let params = {
					fullname: "",
					id_number: "",
					tel: "",
					scene_photo: []
				}
				this.entourage.push(params)
			},
			deletePerson(key) {
				this.entourage.splice(key, 1)
			},
			confirmStartDate(date) {
				this.show.startDateShow = false;
				this.data.start_date = Utils.formatDate(date, "/");
				this.show.startDate = date;
				this.show.endDateShow = true;
			},
			confirmEndDate(date) {
				this.show.endDateShow = false;
				this.data.end_date = Utils.formatDate(date, "/");
			},
			clearDate() {
				this.data.start_date = '';
				this.data.end_date = '';
				this.show.startDateShow = false;
				this.show.endDateShow = false;
			},
			onSubmit() {
				let data = {
					id: this.data.id,
					id_number: this.data.id_number,
					fullname: this.data.fullname,
					tel: this.data.tel,
					address: this.data.address,
					house_photo: this.data.house_photo,
					scene_photo: this.data.scene_photo,
					start_date: this.data.start_date,
					end_date: this.data.end_date,
					work_place: this.data.work_place,
					renting_contract: this.data.renting_contract,
					entourage: []
				}

				data.house_photo = this.getUploadImage(this.show.housePhoto)
				data.scene_photo = this.getUploadImage(this.show.scenePhoto)
				data.renting_contract = this.getUploadImage(this.show.rentingContract)

				let result = this.validator.checkData(data, result => {
					this.$toast(result.msg);
				})

				if (false == result) {
					return false
				}

				if (this.data.entourage == '1') {
					for (let key in this.entourage) {
						let temp = {
							fullname: this.entourage[key].fullname,
							id_number: this.entourage[key].id_number,
							tel: this.entourage[key].tel,
							scene_photo: this.getUploadImage(this.entourage[key].scene_photo)
						}
						temp.scene_photo = temp.scene_photo.length > 0 ? temp.scene_photo[0] : "";

						let result = this.entourageValidator.checkData(this.entourage[key], result => {
							this.$toast(result.msg);
						})

						if (false == result) {
							return false
						}
						data.entourage.push(temp)
					}
				}

				data.house_photo = data.house_photo[0]
				data.scene_photo = data.scene_photo[0]

				this.$toast.loading({
					message: "正在提交..."
				})

				this.$axios.post("/proxy/send", {
					params: {
						url: "/tenant/edit"
					},
					data: data
				}).then((result) => {
					if (result.code == 2000) {
						this.$toast.success("修改成功");
						this.$router.go(-1);
					} else {
						this.$toast.fail(result.msg)
					}
				})
			},
			uploadHouse(file) {
				Utils.upload(file, 'house_photo')
			},
			uploadScene(file) {
				Utils.upload(file, 'scene_photo')
			},
			uploadContract(file) {
				Utils.upload(file, 'renting_contract')
			},
			getUploadImage(data) {
				let result = [];
				for (let i = 0; i < data.length; i++) {
					if (!Utils.isEmpty(data[i].relpath)) {
						result.push(data[i].relpath)
					}
				}
				return result
			},
			validatorInit() {
				// 初始化验证器
				this.validator = new Validator({
					rules: {
						house_photo: {
							required: true
						},
						scene_photo: {
							required: true
						},
						tel: {
							mobile: true,
							required: true
						},
						start_date: {
							required: true
						},
						end_date: {
							required: true
						},
						work_place: {
							required: true
						},
						renting_contract: {
							required: true
						}
					},
					messages: {
						house_photo: {
							required: "请上传房屋照片"
						},
						scene_photo: {
							required: "请上传本人照片"
						},
						tel: {
							mobile: "联系电话不正确",
							required: "请输入联系电话",
						},
						start_date: {
							required: "请选择承租日期"
						},
						end_date: {
							required: "请选择承租日期"
						},
						work_place: {
							required: "请输入工作单位"
						},
						renting_contract: {
							required: "请上传租房合同"
						}
					}
				})


				this.entourageValidator = new Validator({
					rules: {
						fullname: {
							required: true
						},
						id_number: {
							idcard: true,
							required: true
						},
						tel: {
							mobile: true,
							required: true
						},
						scene_photo: {
							required: true
						}
					},
					messages: {
						fullname: {
							required: "请输入随行人员姓名"
						},
						id_number: {
							idcard: "随行人员身份证号码不正确",
							required: "请输入随行人员身份证号码"
						},
						tel: {
							mobile: "随行人员联系电话不正确",
							required: "请输入随行人员联系电话",
						},
						scene_photo: {
							required: "请上传随行人员现场照片"
						}
					}
				})
			},
		},
		components: {
			Preloading
		}
	}
</script>

<style lang="less" scoped>
	@import "@/assets/less/form.less";

	.declare {
		background-color: #F2F2F2;
		padding-top: 54px;
		box-sizing: border-box;

		.add {
			padding: 0 16px;

			.add-flex {
				display: flex;

				.add-item {
					flex: 1;
					font-size: 15px;
					height: 40px;
					line-height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.add-create {
					color: #1772D1;
				}

				.add-delete {
					color: #FF0000;
				}
			}
		}

		.submit {
			padding-bottom: 40px;
		}
	}
</style>
