<template>
	<div class="house container" ref="scroll">
		<van-nav-bar title="我的租房" left-arrow @click-left="back()" fixed />
		<van-list v-model="loading" :finished="finished" finished-text="没有更多啦" @load="loadData">
			<div class="house-wrap cell-list-wrap">
				<div :class="['cell-list', item.status == 4 ||  item.is_expired == 1 ? 'cell-disabled' : '']" v-for="item in house">
					<template v-if="item.is_expired == 1 && item.status != 4">
						<span class="cell-list-state cell-list-state-error">已到期</span>
					</template>
					<template v-else>
						<span v-if="item.status == 1" class="cell-list-state cell-list-state-error">待确认</span>
						<span v-if="item.status == 3" class="cell-list-state cell-list-state-error">待解约</span>
						<span v-if="item.status == 4" class="cell-list-state cell-list-state-error">已解约</span>
					</template>

					<div class="cell-list-item">
						<van-icon name="user-o" />
						<div class="cell-list-item-title">房东姓名：</div>
						<div class="cell-list-item-text">{{item.landlord_fullname}}</div>
					</div>
					<div class="cell-list-item">
						<van-icon name="phone-o" />
						<div class="cell-list-item-title">电话：</div>
						<div class="cell-list-item-text">{{item.landlord_tel}}</div>
					</div>
					<div class="cell-list-item">
						<van-icon name="location-o" />
						<div class="cell-list-item-title">租房地址：</div>
						<div class="cell-list-item-text">
							<span class="cell-wrap-text">{{item.house_address}}</span>
						</div>
					</div>
					<div class="cell-list-item">
						<van-icon name="hotel-o" />
						<div class="cell-list-item-title">详细楼层：</div>
						<div class="cell-list-item-text">{{detailAddress(item)}}</div>
					</div>
					<div class="cell-list-item">
						<van-icon name="clock-o" />
						<div class="cell-list-item-title">承租日期：</div>
						<div class="cell-list-item-text">{{formatDate(item.start_date)}} - {{formatDate(item.end_date)}}</div>
					</div>
					<div class="cell-list-btns van-hairline--top">
						<van-button type="info" size="small" color="#46B7FB" :to="{name: 'TenantDetail', params: {id: item.id}}">房屋详情</van-button>
						<template v-if="item.is_expired == 0">
							<van-button v-if="item.status == 1" type="info" size="small" color="#FF976A" :to="{name: 'TenantEdit', params: {id: item.id}}">修改信息</van-button>
							<van-button v-if="item.status == 2" type="info" size="small" color="#07C160" :to="{name: 'TenantRenew', params: {id: item.id}}">我要续约</van-button>
							<van-button v-if="item.status == 2" type="info" size="small" color="#FF976A" @click="contract(item)">我要解约</van-button>
							<van-button v-if="item.status == 3" type="info" size="small" color="#FF976A" @click="revoke(item)">撤销解约</van-button>
							<van-button v-if="item.status == 2" type="info" size="small" color="#FF976A" :to="{name: 'EntourageCreate', params: {tid: item.id}}">添加随行人员</van-button>
						</template>
					</div>
				</div>
			</div>
		</van-list>
		<div class="empty" v-if="house.length <= 0">
			<van-empty description="未查询到相关数据" />
		</div>
		<Preloading :loading="preloading" />
	</div>
</template>

<script>
	import Utils from '@/utils/util'
	import Preloading from "@/components/Preloading"

	const keepAlive = ["TenantDetail"];

	export default {
		name: "Tenant",
		data() {
			return {
				house: [],
				loading: false,
				finished: false,
				preloading: true,
				page: {
					start: 0,
					limit: 10,
				}
			}
		},
		methods: {
			reload() {
				this.preloading = true;
				this.house = [];
				this.page.start = 0;
				this.loadData();
			},
			loadData() {
				this.$axios.post('/proxy/send', {
					params: {
						url: "/tenant/lists"
					},
					data: this.page
				}).then(res => {
					if (res.code == 2000) {
						this.loading = false;
						this.house = this.house.concat(res.data.list);
						this.page.start = this.house.length;
						if (this.house.length >= res.data.total) {
							this.finished = true;
						}
						this.preloading = false;
					} else {
						this.$toast.fail(res.msg);
					}
				});
			},
			// 房屋解约
			contract(data) {
				this.$dialog.confirm({
					title: '提示',
					message: "确认申请解约吗？",
				}).then(() => {
					this.$toast.loading({
						message: "正在提交"
					})
					this.$axios.post('/proxy/send', {
						params: {
							url: "/tenant/relieve"
						},
						data: {
							id: data.id
						}
					}).then(res => {
						if (res.code == 2000) {
							// 更新为待解约状态
							data.status = 3;
							this.$toast.success("提交成功！");
						} else {
							this.$toast.fail(res.msg);
						}
					});
				});
			},
			// 撤销解约
			revoke(data) {
				this.$dialog.confirm({
					title: '提示',
					message: "确认撤销解约申请吗？",
				}).then(() => {
					this.$toast.loading({
						message: "正在提交"
					})
					this.$axios.post('/proxy/send', {
						params: {
							url: "/tenant/revoke"
						},
						data: {
							id: data.id
						}
					}).then(res => {
						if (res.code == 2000) {
							// 更新为已撤销状态
							data.status = 2;
							this.$toast.success("撤销成功！");
						} else {
							this.$toast.fail(res.msg);
						}
					});
				});
			},
			detailAddress(data) {
				return Utils.detailAddress(data)
			},
			formatDate(date) {
				return Utils.formatDate(new Date(date), "/")
			}
		},
		beforeRouteEnter(to, from, next) {
			next((vm) => {
				vm.$keepAlive.enter(to, from, vm, keepAlive);
			});
		},
		beforeRouteLeave(to, from, next) {
			this.$keepAlive.leave(to, from, this, keepAlive);
			next();
		},
		components: {
			Preloading
		}
	}
</script>

<style lang="less" scoped>
	.house {
		background-color: #F2F2F2;
		padding-top: 50px;
	}
</style>
