<template>
	<div class="help container" ref="scroll">
		<van-nav-bar title="帮助列表" left-arrow @click-left="back()" fixed />
		<van-list v-model="loading" :finished="finished" finished-text="没有更多啦" @load="loadData">
			<div class="card">
				<div class="card-body">
					<router-link tag="div" class="help-item van-hairline--bottom" v-for="item in help" :to="{name: 'HelpDetail', params: { id: item.id }}">
						<p>{{item.title}}</p>
						<i class="van-icon van-icon-arrow"></i>
					</router-link>
				</div>
			</div>
		</van-list>
		<div class="empty" v-if="help.length <= 0">
			<van-empty description="未查询到相关数据" />
		</div>
		<Preloading :loading="preloading" />
	</div>
</template>

<script>
	import Preloading from "@/components/Preloading"

	const keepAlive = ["HelpDetail"];

	export default {
		name: "Help",
		data() {
			return {
				help: [],
				loading: false,
				finished: false,
				preloading: true,
				page: {
					start: 0,
					limit: 20,
				}
			}
		},
		methods: {
			loadData() {
				this.$axios.post('/proxy/send', {
					params: {
						url: "/article/lst"
					},
					data: this.page
				}).then(res => {
					if (res.code == 2000) {
						this.help = this.help.concat(res.data.list);
						this.page.start = this.help.length;
						this.loading = false;
						this.preloading = false;
						if (this.help.length >= res.data.total) {
							this.finished = true;
						}
					} else {
						this.$toast.fail(res.msg)
					}
				});
			}
		},
		beforeRouteEnter(to, from, next) {
			next((vm) => {
				vm.$keepAlive.enter(to, from, vm, keepAlive);
			});
		},
		beforeRouteLeave(to, from, next) {
			this.$keepAlive.leave(to, from, this, keepAlive);
			next();
		},
		components: {
			Preloading
		}
	}
</script>

<style lang="less" scoped>
	.help {
		background-color: #F2F2F2;
		padding-top: 50px;

		.help-item {
			padding: 15px 0;
			margin: 0 15px;
			position: relative;

			p {
				margin: 0;
				font-size: 18px;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				padding-right: 30px;
				color: #666;
			}

			.van-icon {
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				color: #969799;
				font-size: 16px;
			}
		}
	}
</style>
