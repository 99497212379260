<template>
	<div class="detail container">
		<van-nav-bar title="居住证详情" left-arrow @click-left="back()" fixed />
		<div class="card">
			<div class="card-body">
				<div class="cell van-hairline--bottom">
					<div class="cell-title">申领状态</div>
					<div class="cell-value">
						<van-tag size="medium" type="danger" v-if="detail.status == 1">待审核</van-tag>
						<van-tag size="medium" type="success" v-if="detail.status == 2">审核通过</van-tag>
						<van-tag size="medium" type="danger" v-if="detail.status == 3">未通过</van-tag>
					</div>
				</div>
				<div class="cell van-hairline--bottom" v-if="detail.status == 3">
					<div class="cell-title">未通过原因</div>
					<div class="cell-value">{{detail.refuse_reason}}</div>
				</div>
				<div class="cell van-hairline--bottom">
					<div class="cell-title">申领人</div>
					<div class="cell-value">{{detail.fullname}}</div>
				</div>
				<div class="cell van-hairline--bottom">
					<div class="cell-title">电话</div>
					<div class="cell-value">{{detail.tel}}</div>
				</div>
				<div class="cell van-hairline--bottom">
					<div class="cell-title">证件号</div>
					<div class="cell-value">{{detail.id_number}}</div>
				</div>
				<div class="cell van-hairline--bottom">
					<div class="cell-title">户籍地址</div>
					<div class="cell-value">{{detail.id_address}}</div>
				</div>
				<div class="cell van-hairline--bottom">
					<div class="cell-title">房屋地址</div>
					<div class="cell-value">{{detail.address}}</div>
				</div>
				<div class="cell van-hairline--bottom">
					<div class="cell-title">详细楼层</div>
					<div class="cell-value">{{detailAddress(detail)}}</div>
				</div>
				<div class="cell van-hairline--bottom">
					<div class="cell-title">申领时间</div>
					<div class="cell-value">{{detail.create_time}}</div>
				</div>
				<div class="cell van-hairline--bottom">
					<div class="cell-title">居住事由</div>
					<div class="cell-value">{{detail.live_cause}}</div>
				</div>
				<div class="cell van-hairline--bottom">
					<div class="cell-title">证件照片</div>
					<div class="cell-value">
						<div class="cell-value">
							<img class="cell-image" @click="preview(detail.id_fp_url)" :src="detail.id_fp_url" alt="">
						</div>
					</div>
				</div>
				<div class="cell van-hairline--bottom">
					<div class="cell-title">现场照片</div>
					<div class="cell-value">
						<div class="cell-value">
							<img class="cell-image" @click="preview(detail.self_photo_url)" :src="detail.self_photo_url" alt="">
						</div>
					</div>
				</div>
			</div>
		</div>
		<Preloading :loading="preloading" />
	</div>
</template>

<script>
	import Utils from '@/utils/util'
	import Preloading from "@/components/Preloading"

	export default {
		name: 'ResidencePermitDetail',
		data() {
			return {
				detail: "",
				preloading: true,
			}
		},
		created() {
			// 获取房屋详情
			let params = this.$route.params;
			this.$axios.post('/proxy/send', {
				params: {
					url: "/residence_permit/detail"
				},
				data: {
					id: params.id
				}
			}).then(result => {
				if (result.code == 2000) {
					this.detail = result.data;
					this.preloading = false;
				} else {
					this.$toast.fail(result.msg)
				}
			});
		},
		methods: {
			preview(images, index) {
				Utils.preview(images, index)
			},
			detailAddress(data) {
				return Utils.detailAddress(data)
			}
		},
		components: {
			Preloading
		}
	}
</script>

<style lang="less" scoped>
	.detail {
		background-color: #F2F2F2;
		padding-top: 50px;

		.card-body {
			padding: 0 10px 10px;
		}

		.confirm {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
		}

		span.van-tag {
			font-size: 16px;
			line-height: 1.2;
		}
	}
</style>
